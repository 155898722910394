import { useMemo, useCallback } from 'react';

import { useFetch } from '~/lib/Effects.Fetch';

export function useFetchLanguages() {
  const url = useMemo(() => {
    return `language/?format=json`;
  }, []);

  const handleLanguagesResponse = useCallback((response) => {
    return (!!response && response.objects) || null;
  }, []);

  return useFetch(url, handleLanguagesResponse);
}