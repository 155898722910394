import React from "react";

import { NavLink } from "react-router-dom";

import { MenuHoverDropdownGlobalStyle } from './MainMenu/NavigationItem';

// Components
import UserAvatar from './MainMenu/UserAvatar';
import LanguageChooser from "./MainMenu/LanguageChooser";
import MenuToggler from './MainMenu/MenuToggler';
import NavigationItem from './MainMenu/NavigationItem';
import DescriptionPanel from './DescriptionPanel';

function Navigation({ user }) {
  return (
    <React.Fragment>
      <MenuHoverDropdownGlobalStyle />

      <ul className="navbar-nav ml-auto">
        {/* <NavigationItem id="menu-home" label="Home" icon="home" route="/">
          <DescriptionPanel.Home />
        </NavigationItem> */}

        <NavigationItem id="menu-library" label="Library" route="/library/source" />

        <NavigationItem id="menu-reading-list" label="Reading Lists" route="/reading-list" />

        {/*<NavigationItem label="Workspaces" route="/workspace" />*/}

        <NavigationItem id="menu-analyics" label="Analytics">
          <DescriptionPanel.Analytics />
        </NavigationItem>

        <NavigationItem id="menu-social-media" label="Social media" route="/social/polarity-score" />

        <NavigationItem id="menu-manage" label="Manage" route={`${process.env.REACT_APP_URLBACKEND}/app`} />

        <LanguageChooser />
      </ul>


      {!user ? (
        <NavLink exact={true} className="navbar-btn btn btn-sm btn-primary lift ml-auto" to="/login" style={{ marginTop: '-0.5625rem' }}>
          Sign in
        </NavLink>
      ) : (
          <UserAvatar />
        )}
    </React.Fragment>
  );
}

export default function MainMenu({ user }) {
  return (
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <MenuToggler />
      <Navigation user={user} />
    </div>
  );
}
