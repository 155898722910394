import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { useWindowEvent } from '~/lib/Effects';


const SideBarStyled = styled.div`
  width: 100%;
  max-width: 100%;

  max-height: 100vh;
  overflow-y: auto;

  z-index: 2;

  @media (max-width: 991px) {
    transform: translateY(0) !important;
  }

  @media (min-width: 992px) {
    position: fixed;
    width: 33.33333%;
    min-width: 33.33333%;
  }
`;

export default function SideBar({ id, children }) {

  const [sidebarInitialTop, setSidebarInitialTop] = useState(0);
  const [windowScrollTop, setWindowScrollTop] = useState(0);

  const sidebarRef = useCallback((node) => {
    if (!!node) {
      setSidebarInitialTop(node.getBoundingClientRect().top);
    }
  }, []);

  const handleWindowScroll = useCallback(() => {
    setWindowScrollTop(window.scrollY);
  }, []);

  useWindowEvent('scroll', handleWindowScroll);

  return (
    <SideBarStyled
      id={id}
      className="col-lg-4 pl-0"
      ref={sidebarRef}
      style={{
        transform: `translateY(-${windowScrollTop > sidebarInitialTop - 20 ? sidebarInitialTop - 20 : windowScrollTop}px)`,
        maxHeight: `calc(100vh - ${windowScrollTop > sidebarInitialTop - 20 ? 20 : sidebarInitialTop - windowScrollTop}px)`
      }}
    >
      {children}
    </SideBarStyled>
  );
}