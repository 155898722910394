import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

// Components
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

export default function SourceTypeSelector({ sourceTypes, value, onChange = noop }) {
  const handleSourceTypeChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!sourceTypes && !!sourceTypes.length ? (
        <select className="form-control form-control-sm" value={value} onChange={handleSourceTypeChange}>
          <option value="">All</option>
          {sourceTypes.map(({ code, name }, indx) => (
            <option key={`content-type-${indx}`} value={code}>{name}</option>
          ))}
        </select>
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}

    </React.Fragment>
  );
}