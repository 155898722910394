export const initialState = {
  loading: 0,
  language: localStorage.getItem('lang') || 'en',
  showDescriptionPanels: Boolean(localStorage.getItem('showDescriptionPanels')) || true
};

export const symbiotes = {
  loading: {
    start: (state) => ({ ...state, loading: state.loading + 1 }),
    stop: (state) => ({ ...state, loading: state.loading - Math.sign(state.loading) })
  },
  app: {
    setLanguage: (state, language) => {
      localStorage.setItem('lang', language);

      return ({ ...state, language });
    },
    disableDescriptionPanels: (state) => {
      localStorage.setItem('showDescriptionPanels', false);

      return ({ ...state, showDescriptionPanels: false });
    }
  }
};