import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from '../router/Routes.js';

import MainView from '../modules/main/views/MainView';

import ConfigurationProvider from '../config';
import { StoreProvider } from "../store/StoreProvider";
import { ToastProvider } from 'react-toast-notifications';
import { LocalizationProvider } from '../i18n/LocalizationProvider';

import CookieBanner from '../modules/main/components/App/CookieBanner';
import ToastContainer from '../components/notify/ToastContainer';


function App() {
  return (
    <ConfigurationProvider>
      <StoreProvider>
        <ToastProvider autoDismiss={true} components={{ ToastContainer }}>
          <Router>
            <LocalizationProvider>
              <MainView>
                <Routes />
              </MainView>
            </LocalizationProvider>
          </Router>
        </ToastProvider>
        <CookieBanner />
      </StoreProvider>
    </ConfigurationProvider>
  );
}

export default App;
