import React, { useContext } from 'react';

import { useLocation, Route, Redirect } from 'react-router-dom';
import { Store } from '../store/StoreProvider';

export default function AuthenticatedRoute({ children, ...rest }) {
  const { state } = useContext(Store);
  const location = useLocation();

  return (
    <React.Fragment>
      {!!state.user.data ? (
        <Route {...rest}>
          {children}
        </Route>
      ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )}
    </React.Fragment>
  );
}