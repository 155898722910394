import React, { useMemo, useCallback, useRef, useLayoutEffect, useState } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { noop, stopPreventEvent } from '~/lib/Utils';

// Components
import SearchResultItem from './SearchResultItem';
import LoadingIndicator from '~/components/indicator/LoadingIndicator';
import InfiniteScroll from '~/components/scroll/InfiniteScroll';  //'react-infinite-scroller';

export default function SearchResults({
  results,
  meta,
  crlSelection,
  crlEnable = true,
  onTopicSelect,
  onFetchNext = noop,
  onCrlSourceSelect = noop
}) {

  const loader = useMemo(() => {
    return <LoadingIndicator size={1} length={3} className="text-primary-desat py-5" />
  }, []);

  const getHandleCrlSourceSelect = useGetCallback(
    (source) =>
      (e) => {
        stopPreventEvent(e);

        onCrlSourceSelect(source);
      },
    source => source.sourceId,
    [onCrlSourceSelect]
  );

  const canLoad = useRef(true);
  const [refreshTrigger, setRefreshTrigger] = useState({});

  const items = useMemo(() => {
    return (
      <div className="list-group list-group-flush">
        {results.map((item, indx) => {

          const itemSelected = !!item
            && item.sourceType.toLowerCase() === 'app:sources:librarysource'
            && (!!crlSelection && crlSelection.some(c => c.sourceId === item.sourceId));

          return (
            <div
              key={`item-${indx}`}
              className="list-group-item text-reset mt-2"
              data-aos="fade-up"
            >
              <SearchResultItem
                item={item}
                crlEnable={crlEnable}
                crlSelected={itemSelected}
                onTopicSelect={onTopicSelect}
                onCrlSourceSelect={getHandleCrlSourceSelect(item)}
              />
            </div>
          )
        })}
      </div>
    );
  }, [results, onTopicSelect, getHandleCrlSourceSelect, crlSelection, crlEnable, refreshTrigger /* it is needed because ref does not cause refresh */]);


  const handleFetchNext = useCallback(() => {
    canLoad.current = false;
    onFetchNext();
  }, [onFetchNext]);

  useLayoutEffect(() => {
    canLoad.current = true;
    setRefreshTrigger({});
  }, [results]);

  return (
    <div className="d-flex flex-column w-100 mt-4">
      {!!results.length ? (
        <div className="card card-border border-primary shadow-light-lg mt-3 aos-init aos-animate" data-aos="fade-up">
          <div className="card-body">

            <h6 className="text-uppercase text-primary d-inline-block mb-5 mr-1">Found:</h6>
            <span className="badge badge-rounded-circle badge-info-soft">{meta.totalCount}</span>

            <div>
              <InfiniteScroll
                hasMore={(results.length < meta.totalCount) && canLoad.current}
                loadMore={handleFetchNext}
                loader={loader}
                initialLoad={false}
                useWindow={true}
                useCapture={true}
              >
                {items}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      ) : (
          <div className="h4 text-center mt-5">No results found</div>
        )}
    </div>
  );
}