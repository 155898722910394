import React, { lazy, useMemo, useRef, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';

import { is } from '~/lib/Utils';

// Components
import LogInPage from '~/modules/user/pages/LogInPage';
import NotFoundPage from '~/modules/main/pages/NotFoundPage';
import NotAuthorizedPage from '~/modules/main/pages/NotAuthorizedPage';
import SearchPage from '~/modules/search/pages/SearchPage';

import PageLoadingPlaceholder from '~/modules/main/components/App/PageLoadingPlaceholder';
import PaceIndicator from '~/modules/main/components/App/PaceIndicator';


const HomePageLoadable = lazy(() => import(/* webpackChunkName:"home" */ '~/modules/main/pages/HomePage'));
const AboutPageLoadable = lazy(() => import(/* webpackChunkName:"about" */ '~/modules/main/pages/AboutPage'));
const TopicExplorerPageLoadable = lazy(() => import(/* webpackChunkName:"explorer" */ '~/modules/explorer/pages/TopicExplorerPage'));
const ModelExplorerPageLoadable = lazy(() => import(/* webpackChunkName:"explorer" */ '~/modules/explorer/pages/ModelExplorerPage.js'));
const PolarityScorePageLoadable = lazy(() => import(/* webpackChunkName:"explorer" */ '~/modules/explorer/pages/PolarityScorePage'));
const SocialPolarityScorePageLoadable = lazy(() => import(/* webpackChunkName:"explorer" */ '~/modules/social/pages/SocialPolarityScorePage'));
const WorkspacesPageLoadable = lazy(() => import(/* webpackChunkName:"workspaces" */ '~/modules/workspaces/pages/WorkspacesPage'));
const SocialMediaPageLoadable = lazy(() => import(/* webpackChunkName: "social-media" */ '~/modules/socialMedia/pages/SocialMediaPage'));
const LibrarySourceListPageLoadable = lazy(() => import(/* webpackChunkName:"admin-library" */'~/modules/library/pages/SourceListPage'));
const LibrarySourceDetailsPageLoadable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/SourceDetailsPage'));

const LibrarySourceListFilterModalLodable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/SourceListFilterModal'));
const LibrarySourceDetailsModalLoadable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/SourceDetailsParagraphModal'));
const LibraryReadingListModalLoadable = lazy(() => import(/* webpackChunkName: "admin-library"  */ '~/modules/library/pages/ReadingListSourceModal'));
const LibraryReadingListPageLoadable = lazy(() => import(/* webpackChunkName: "admin-library"  */ '~/modules/library/pages/ReadingListPage'));
const LibraryReadingListDetailsPageLoadable = lazy(() => import(/* webpackChunkName: "admin-library"  */ '~/modules/library/pages/ReadingDetailsPage'));
const LibraryReadingListFilterModalLodable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/ReadingListFilterModal'));
const LibraryReadingListDetailsModalLoadable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/ReadingDetailsParagraphModal'));
const LibraryReadingListErrorsModalLodable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/ReadingListErrorsModal'));
const LibraryReadingListWordCountModalLodable = lazy(() => import(/* webpackChunkName: "admin-library" */ '~/modules/library/pages/ReadingListWordCountModal'));

const PageLoadingPlaceholderMemoized = React.memo(PageLoadingPlaceholder);

const isLocationModal = (location) =>
  is.notUndef(location) && is.notUndef(location.pathname) && location.pathname.startsWith('/modal/');

const isLocationAuthentication = (location) =>
  is.notUndef(location) && is.notUndef(location.pathname) && location.pathname.startsWith('/login');

function usePreviousLocation(location, history) {
  const previousLocation = useRef(location);

  useEffect(() => {
    if (!isLocationModal(location) && !isLocationAuthentication(location)) {
      previousLocation.current = location;
    }
  }, [location, history]);

  return previousLocation.current;
}

export default function Routes() {
  const location = useLocation();
  const history = useHistory();

  const previousLocation = usePreviousLocation(location, history);

  const isModal = useMemo(() => {
    return isLocationModal(location) && location.key !== previousLocation.key;
  }, [location, previousLocation]);

  return (
    <React.Fragment>
      <PaceIndicator />
      <React.Suspense fallback={<PageLoadingPlaceholderMemoized />}>
        <Switch location={isModal ? previousLocation : location}>
          <Route path="/" exact={true}>
            <HomePageLoadable />
          </Route>

          <Route path="/login" exact={true}>
            <LogInPage />
          </Route>

          <Route path="/about" exact={true}>
            <AboutPageLoadable />
          </Route>

          <Route path="/explorer" exact={true}>
            <TopicExplorerPageLoadable />
          </Route>

          <AuthenticatedRoute path="/explorer/crl/:modelId/:modelTopic" exact={true}>
            <ModelExplorerPageLoadable />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/polarity-score/:scope?" exact={true}>
            <PolarityScorePageLoadable />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/polarity-score/:scope/:sourceId?/:srcType?" exact={true}>
            <PolarityScorePageLoadable />
          </AuthenticatedRoute>

          <Route path="/social/polarity-score" exact={true}>
            <SocialPolarityScorePageLoadable />
          </Route>

          <AuthenticatedRoute path="/workspace" exact={true}>
            <WorkspacesPageLoadable />
          </AuthenticatedRoute>

          <Route path="/social-media" exact={true}>
            <SocialMediaPageLoadable />
          </Route>

          <Route path='/search/q=:term?' exact={true}>
            <SearchPage />
          </Route>
          <Route path='/search/:scope?' exact={true}>
            <SearchPage />
          </Route>
          <Route path='/search/:scope/q=:term?' exact={true}>
            <SearchPage />
          </Route>

          <Route path="/library/source" exact={true}>
            <LibrarySourceListPageLoadable />
          </Route>

          <Route path="/library/source/:id/:paragraph?/:subparagraph?" exact={true}>
            <LibrarySourceDetailsPageLoadable />
          </Route>

          <AuthenticatedRoute path="/reading-list/:id" exact={true}>
            <LibraryReadingListDetailsPageLoadable />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/reading-list" exact={true}>
            <LibraryReadingListPageLoadable />
          </AuthenticatedRoute>

          <Route path="/notauthorized" exact={true}>
            <NotAuthorizedPage />
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>

        {isModal && (
          <Switch location={location}>
            <Route path="/modal/library/source/filter" exact={true}>
              <LibrarySourceListFilterModalLodable />
            </Route>

            <Route path="/modal/library/source/details/:id/:order/:sub" exact={true}>
              <LibrarySourceDetailsModalLoadable />
            </Route>

            <AuthenticatedRoute path="/modal/reading-list/create/:mode?" exact={true}>
              <LibraryReadingListModalLoadable />
            </AuthenticatedRoute>

            <AuthenticatedRoute path="/modal/reading-list/filter" exact={true}>
              <LibraryReadingListFilterModalLodable />
            </AuthenticatedRoute>

            <AuthenticatedRoute path="/modal/reading-list/errors/:id" exact={true}>
              <LibraryReadingListErrorsModalLodable />
            </AuthenticatedRoute>

            <AuthenticatedRoute path="/modal/reading-list/word-count/:id" exact={true}>
              <LibraryReadingListWordCountModalLodable />
            </AuthenticatedRoute>

            <AuthenticatedRoute path="/modal/reading-list/details/:sourceType/:id/:order/:sub" exact={true}>
              <LibraryReadingListDetailsModalLoadable />
            </AuthenticatedRoute>
          </Switch>
        )}
      </React.Suspense>
    </React.Fragment>
  )
}