import React, { useCallback, useState, useEffect } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { noop } from '~/lib/Utils';

export default function SearchSelectedSourcesPanel({
  sources,
  onSourceRemove = noop,
  onSelectedSourcesShowChange = noop,
  onApply = noop }) {

  const [localShowSelection, setLocalShowSelection] = useState(false);

  const handleShowSelectionClick = useCallback((e) => {
    e.currentTarget.blur();
    setLocalShowSelection(f => !f);
  }, []);

  useEffect(() => {
    onSelectedSourcesShowChange(localShowSelection);
  }, [onSelectedSourcesShowChange, localShowSelection]);

  const handleApplyClick = useCallback(() => {
    setLocalShowSelection(false);
    onApply();
  }, [setLocalShowSelection, onApply]);

  const getHandleSourceRemove = useGetCallback(
    (source) =>
      () => {
        onSourceRemove(source);
      },
    source => source.sourceId,
    [onSourceRemove]
  );

  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-row">
        <button
          className="btn btn-primary-soft btn-pill btn-sm lift"
          onClick={handleShowSelectionClick}
        >
          <i className="fe fe-filter mr-2" alt="Filters" />
          Selected sources
          <i className={`fe fe-chevron-${localShowSelection ? 'up' : 'down'} ml-2`} />
        </button>
      </div>

      {localShowSelection && (
        <div className="card w-100 mt-5 shadow-light">
          <div className="card-body">
            <div className="list-group list-group-flash">
              {!!sources && sources.map((source, indx) => (
                <div className="list-group-item" key={`source-selected-${indx}`}>
                  <div className="d-flex w-100 justify-content-between">
                    <span>
                      <i className="fe fe-link mr-1" />
                      <span className="dont-break-out">
                        {source.url}
                      </span>
                    </span>
                    <i
                      className="fe fe-x-circle text-danger pointable"
                      title="Remove from selected sources"
                      onClick={getHandleSourceRemove(source)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="card-meta">
            <hr className="card-meta-divider" />
            <button
              className="btn btn-sm btn-primary-soft ml-auto lift"
              title="Add to CRL"
              onClick={handleApplyClick}>
              Add to CRL
            </button>
          </div>
        </div>
      )}
    </div>
  )
}