import { updateState } from '~/lib/Utils.Store.js';

export const initialState = {
  library: {
    source: {
      filters: {
        language: localStorage.getItem('lang') || 'en',
        sourceSize: {},
        contentType: '',
        sourceType: '',
        owner: '',
        region: '',
        created: {},
        updated: {},
        active: true,
        topics: { topics: [], constraint: 'all' }
      },
      dictionaries: {
        sourceTypes: []
      },
      list: {
        start: 0,
        rows: 20,
        sort: {},
        selectedSources: [] // sources table
      },
      details: {
        showPolarity: true,
        showEntities: true,
        showTopics: true,
        showLocations: true
      }
    },
    readingList: {
      list: {
        start: 0,
        rows: 20,
        sort: {},
        newSources: [], // urls to add to a reading list
        selectedLists: [], // reading lists table
        useSiteMap: false,
      },
      filters: {
        active: true,
        topics: { topics: [], constraint: 'all' }
      },
      details: {
        sourcesEditMode: false,
        newSources: [], // urls to add to a reading list
        sourcesToRemove: [], // urls to remove from a reading lsit
        showPolarity: true,
        showEntities: true,
        showTopics: true,
        showLocations: true
      }
    }
  }
};

export const symbiotes = {
  library: {
    source: {
      filters: {
        reset: state => updateState(state, ['library', 'source'], { filters: initialState.library.source.filters }),
        languageChange: (state, language) => updateState(state, ['library', 'source', 'filters'], { language }),
        languageDelete: state => updateState(state, ['library', 'source', 'filters'], { language: '' }),
        sourceSizeConstraintChange: (state, constraint) => updateState(state, ['library', 'source', 'filters', 'sourceSize'], { constraint }),
        sourceSizeValueChange: (state, value) => updateState(state, ['library', 'source', 'filters', 'sourceSize'], { value }),
        sourceSizeDelete: state => updateState(state, ['library', 'source', 'filters'], { sourceSize: {} }),
        contentTypeChange: (state, contentType) => updateState(state, ['library', 'source', 'filters'], { contentType }),
        contentTypeDelete: state => updateState(state, ['library', 'source', 'filters'], { contentType: '' }),
        sourceTypeChange: (state, sourceType) => updateState(state, ['library', 'source', 'filters'], { sourceType }),
        sourceTypeDelete: state => updateState(state, ['library', 'source', 'filters'], { sourceType: '' }),
        ownerChange: (state, owner) => updateState(state, ['library', 'source', 'filters'], { owner }),
        ownerDelete: state => updateState(state, ['library', 'source', 'filters'], { owner: '' }),
        regionChange: (state, region) => updateState(state, ['library', 'source', 'filters'], { region }),
        regionDelete: state => updateState(state, ['library', 'source', 'filters'], { region: '' }),
        createdChange: (state, created) => updateState(state, ['library', 'source', 'filters'], { created }),
        createdDelete: state => updateState(state, ['library', 'source', 'filters'], { created: {} }),
        updatedChange: (state, updated) => updateState(state, ['library', 'source', 'filters'], { updated }),
        updatedDelete: state => updateState(state, ['library', 'source', 'filters'], { updated: {} }),
        activeChange: (state, active) => updateState(state, ['library', 'source', 'filters'], { active }),
        activeRemove: state => updateState(state, ['library', 'source', 'filters'], { active: undefined }),
        topicsChange: (state, topics) => updateState(state, ['library', 'source', 'filters'], { topics }),
        topicDelete: (state, topic) => updateState(state, ['library', 'source', 'filters'], {
          topics: {
            topics: state.library.source.filters.topics.topics.filter(t => t !== topic),
            constraint: state.library.source.filters.topics.constraint
          }
        })
      },
      dictionaries: {
        sourceTypesChange: (state, sourceTypes) => updateState(state, ['library', 'source', 'dictionaries'], { sourceTypes })
      },
      list: {
        startChange: (state, start) => updateState(state, ['library', 'source', 'list'], { start }),
        rowsChange: (state, rows) => updateState(state, ['library', 'source', 'list'], { rows }),
        sortChange: (state, sort) => updateState(state, ['library', 'source', 'list'], { sort }),
        selectedSourcesChange: (state, selectedSources) => updateState(state, ['library', 'source', 'list'], { selectedSources }),
        selectedSourcesClear: (state) => updateState(state, ['library', 'source', 'list'], { selectedSources: [] })
      },
      details: {
        showPolarityChange: (state, showPolarity) => updateState(state, ['library', 'source', 'details'], { showPolarity }),
        showEntitiesChange: (state, showEntities) => updateState(state, ['library', 'source', 'details'], { showEntities }),
        showTopicsChange: (state, showTopics) => updateState(state, ['library', 'source', 'details'], { showTopics }),
        showLocationsChange: (state, showLocations) => updateState(state, ['library', 'source', 'details'], { showLocations })
      }
    },
    readingList: {
      list: {
        startChange: (state, start) => updateState(state, ['library', 'readingList', 'list'], { start }),
        rowsChange: (state, rows) => updateState(state, ['library', 'readingList', 'list'], { rows }),
        sortChange: (state, sort) => updateState(state, ['library', 'readingList', 'list'], { sort }),
        newSourceAdd: (state, source) => updateState(
          state,
          ['library', 'readingList', 'list'],
          { newSources: state.library.readingList.list.newSources.concat(source) }),
        newSourceDelete: (state, sourceIndex) => updateState(
          state,
          ['library', 'readingList', 'list'],
          { newSources: state.library.readingList.list.newSources.filter((_i, indx) => indx !== sourceIndex) }
        ),
        selectedListsChange: (state, selectedLists) => updateState(state, ['library', 'readingList', 'list'], { selectedLists }),
        selectedListDeleteById: (state, id) => updateState(
          state,
          ['library', 'readingList', 'list'],
          { selectedLists: state.library.readingList.list.selectedLists.filter(list => list.id !== id) }),
        selectedListsClear: (state) => updateState(state, ['library', 'readingList', 'list'], { selectedLists: [] }),
        useSiteMapChange: (state, useSiteMap) => updateState(state, ['library', 'readingList', 'list'], { useSiteMap }),
      },
      filters: {
        reset: state => updateState(state, ['library', 'readingList'], { filters: initialState.library.readingList.filters }),
        activeChange: (state, active) => updateState(state, ['library', 'readingList', 'filters'], { active }),
        activeRemove: state => updateState(state, ['library', 'readingList', 'filters'], { active: undefined }),
        topicsChange: (state, topics) => updateState(state, ['library', 'readingList', 'filters'], { topics }),
        topicDelete: (state, topic) => updateState(state, ['library', 'readingList', 'filters'], {
          topics: {
            topics: state.library.source.filters.topics.topics.filter(t => t !== topic),
            constraint: state.library.source.filters.topics.constraint
          }
        })
      },
      details: {
        newSourceAdd: (state, source) => updateState(
          state,
          ['library', 'readingList', 'details'],
          { newSources: state.library.readingList.details.newSources.concat(source) }),
        newSourceDelete: (state, sourceIndex) => updateState(
          state,
          ['library', 'readingList', 'details'],
          { newSources: state.library.readingList.details.newSources.filter((_i, indx) => indx !== sourceIndex) }
        ),
        sourceToRemoveAdd: (state, source) => updateState(
          state,
          ['library', 'readingList', 'details'],
          { sourcesToRemove: state.library.readingList.details.sourcesToRemove.concat(source) }),
        sourceToRemoveDelete: (state, sourceIndex) => updateState(
          state,
          ['library', 'readingList', 'details'],
          { sourcesToRemove: state.library.readingList.details.sourcesToRemove.filter((_i, indx) => indx !== sourceIndex) }
        ),
        sourcesEditModeChange: (state, sourcesEditMode) => updateState(state, ['library', 'readingList', 'details'], { sourcesEditMode }),
        showPolarityChange: (state, showPolarity) => updateState(state, ['library', 'readingList', 'details'], { showPolarity }),
        showEntitiesChange: (state, showEntities) => updateState(state, ['library', 'readingList', 'details'], { showEntities }),
        showTopicsChange: (state, showTopics) => updateState(state, ['library', 'readingList', 'details'], { showTopics }),
        showLocationsChange: (state, showLocations) => updateState(state, ['library', 'readingList', 'details'], { showLocations })
      }
    }
  }
};