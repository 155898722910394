import React, { useCallback } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { noop } from '~/lib/Utils';

// Components
import SuggestionSelect from '~/components/input/SuggestionSelect';
import Pills from '~/components/button/Pills';
import ShowSwitch from '../ShowSwitch';

function TopicSuggestionComponent({ data }) {
  return (
    <React.Fragment>
      <span>
        {(data.text && data.text.replace(/_/g, ' ').toLowerCase()) || ''}
      </span>
      <span className="badge badge-primary-soft badge-pill">
        {data.lang || ''}
      </span>
    </React.Fragment>
  );
}

export default function TopicSelector({ topics, loading, value, onTopicsFetch, onChange = noop }) {

  const handleTopicSelect = useCallback((topic) => {
    if (!value.topics.some(t => t === topic.text)) {
      onChange({
        topics: value.topics.concat(topic.text),
        constraint: value.constraint
      });
    }
  }, [value, onChange]);

  const getHandleTopicRemove = useGetCallback(
    (topic) =>
      () => {
        onChange({
          topics: value.topics.filter(t => t !== topic),
          constraint: value.constraint
        });
      },
    topic => topic,
    [value, onChange]
  );

  const handleConstraintAndChange = useCallback((checked) => {
    if (checked) {
      onChange({
        topics: value.topics,
        constraint: 'all'
      });
    }
  }, [value, onChange]);

  const handleConstraintOrChange = useCallback((checked) => {
    if (checked) {
      onChange({
        topics: value.topics,
        constraint: 'any'
      });
    }
  }, [value, onChange]);

  return (
    <React.Fragment>
      {!!value && !!value.topics && (
        <div className="my-2">
          {value.topics.map((topic, indx) => (
            <Pills.BadgePillButtonWithClose
              key={`topic-${indx}`}
              value={topic}
              badgeStyle="primary-soft"
              onFilterRemove={getHandleTopicRemove(topic)}
            />
          ))}
        </div>
      )}
      <SuggestionSelect
        suggestions={topics}
        suggestionKey="text"
        loading={loading}
        placeholder="Start type to search a topic"
        onInputChange={onTopicsFetch}
        onSuggestionSelect={handleTopicSelect}
        SuggestionComponent={TopicSuggestionComponent}
      />

      {!!value && (
        <div className="mt-5">
          <span className="mr-5">
            <ShowSwitch
              id="topic-switch-and"
              className="d-inline-block"
              title={value.constraint === 'any' ? 'Filter by all topics' : undefined}
              label="and"
              checked={value.constraint === 'all'}
              onShowChange={handleConstraintAndChange}
            />
          </span>
          <span className="mr-5">
            <ShowSwitch
              id="topic-switch-or"
              className="d-inline-block"
              title={value.constraint === 'all' ? 'Filter by any topic' : undefined}
              label="or"
              checked={value.constraint === 'any'}
              onShowChange={handleConstraintOrChange}
            />
          </span>
        </div>
      )}
    </React.Fragment>
  );
}