import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

// Components
import InputDebounced from '~/components/input/InputDebounced';
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

export default function SourceSizeSelector({ constraints, value, onConstraintChange = noop, onValueChange = noop }) {
  const handleSourceSizeConstraintChange = useCallback(({ target: { value } }) => {
    onConstraintChange(value);
  }, [onConstraintChange]);

  return (
    <React.Fragment>
      {!!constraints && !!constraints.length ? (
        <div className="d-flex">
          <select className="form-control form-control-sm d-inline-block mr-2" value={value.constraint} onChange={handleSourceSizeConstraintChange}>
            <option value="">Select...</option>
            {constraints.map(({ code, name }, indx) => (
              <option key={`source-size-constraint-${indx}`} value={code}>{name}</option>
            ))}
          </select>

          <InputDebounced
            type="number"
            size={6}
            debounce={600}
            className="form-control form-control-sm mr-2"
            value={value.value || ''}
            onInputChange={onValueChange}
          />

          <span className="small">KBytes</span>
        </div>
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}
    </React.Fragment>
  );
}