import { formatDate, is } from '~/lib/Utils';

export const SourceSizeConstraints = [
  { code: 'lt', name: 'Less than' },
  { code: 'gt', name: 'Greater than' }
];

export function formatStatus(value) {
  return value ? 'Active' : 'Deleted';
}

export function formatLanguageItem(lang) {
  return !!lang
    ? `${lang.name} (${lang.nameOrig})`
    : '...';
};

export function formatDateInterval(interval) {
  let result = '';

  if (!!interval.from) {
    result += `from ${formatDate(interval.from, 'dd.MM.yyyy')} `;
  }

  if (!!interval.to) {
    result += `to ${formatDate(interval.to, 'dd.MM.yyyy')}`;
  }

  return result;
}

export function formatPolarity(polarity) {
  return is.number(polarity)
    ? Number(polarity) > 0
      ? 'positive'
      : Number(polarity) < 0
        ? 'negative'
        : 'neutral'
    : is.array(polarity)
      ? `${polarity[0]}..${polarity[polarity.length - 1]}`
      : 'unknown';
}
