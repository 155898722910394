import React, { forwardRef, useCallback, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Store, actions } from '~/store/StoreProvider';

// Components
import { confirm } from '~/components/modal/Confirmation';

const SettingsButtonStyled = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 70%;
  color: var(--gray);
`;

const ConfirmDescriptionPanelGlobalStyle = createGlobalStyle`
  .confirm-description-panel .dropdown-img-left {
    border-radius: 0.375rem; 
  }

  .confirm-description-panel .dropdown-img-left::before {
    top: 40px;
    left: 20px;
    right: 20px;
    bottom: 40px;
  }
`;

async function confirmDescriptionPanelDisable() {
  return confirm(
    <React.Fragment>
      <ConfirmDescriptionPanelGlobalStyle />
      <div className="navbar-nav confirm-description-panel">
        <div className="dropdown-img-left w-100 text-center" style={{ backgroundImage: 'url(/assets/img/photos/photo-14.jpg)' }}>
          <h4 className="font-weight-bold text-white mb-0">Are you sure you want to disable description panels?</h4>
        </div>
      </div>
    </React.Fragment>,
    'OK',
    'Cancel',
    { enableEscape: false }
  );
}

export default forwardRef(function DropdownHoverMenu({ children }, ref) {
  const { state, dispatch } = useContext(Store);

  const handleSettingsClick = useCallback(async () => {
    if (await confirmDescriptionPanelDisable()) {
      dispatch(actions.app.disableDescriptionPanels());
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      {state.showDescriptionPanels && (
        <div
          className="dropdown-hover-menu dropdown-menu-lg p-0 card"
          ref={ref}
        >
          {children}
          <SettingsButtonStyled className="mr-2 mb-1 pointable" onClick={handleSettingsClick}>
            <i className="fe fe-settings" />
          </SettingsButtonStyled>
        </div>
      )}
    </React.Fragment>
  );
});