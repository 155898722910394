import React from "react";

import Brand from './NavBar/Brand';
import Toggler from './NavBar/Toggler';
import MainMenu from './NavBar/MainMenu';
import SearchBar from './NavBar/SearchBar';

export default function Navbar({ user, showSearchBar = true, onSearch }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
      {showSearchBar && (
        <SearchBar onSearch={onSearch} />
      )}
      <div className="container-fluid">
        <Brand />
        <Toggler />
        <MainMenu user={user} />
      </div>
    </nav>
  );
}