import React from 'react';

// Components
import { Link } from 'react-router-dom';

// TODO: use user.avatar when it is avaialble
export default function UserAvatar({ user }) {
  return (
    // <Link
    //   to="/login"
    //   className="btn btn-rounded-circle btn-primary-soft btn-outline btn-sm ml-5 mr-3 lift"
    //   style={{
    //     border: '2px var(--primary-desat) solid',
    //     marginTop: '-6px',
    //     marginBottom: '-6px',
    //     opacity: 0.8,
    //     transform: 'scale(0.8)' // untill real avatars are available, this scale looks better with the landkit image
    //   }}
    // >
    //   <div className="avatar avatar-lg">
    //     <img src="/assets/img/icons/duotone-icons/General/User.svg" alt="..." className="avatar-img rounded-circle" />
    //   </div>
    // </Link>

    <div
      className="btn btn-rounded-circle btn-primary-soft btn-outline btn-sm ml-5 mr-3 lift"
      style={{
        border: '2px var(--primary-desat) solid',
        marginTop: '-6px',
        marginBottom: '-6px',
        opacity: 0.8,
        transform: 'scale(0.8)' // untill real avatars are available, this scale looks better with the landkit image
      }}
    >
      <div>
        <img src="/assets/img/icons/duotone-icons/General/User.svg" alt="..." className="avatar-img rounded-circle" />
      </div>
    </div>
  );
}