import { useMemo, useState, useEffect, useRef } from 'react';
import { makeCancelable } from './Utils';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

// find more funny hooks at https://usehooks.com/

export function useGetAvailableContainerSize(availableWidth, avaialbleHeight, minWidth, minHeight, containerRef) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(
    () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const computedStyle = getComputedStyle(containerRef.current);

        const widthMargin = Number(computedStyle.paddingLeft.replace('px', '')) +
          Number(computedStyle.paddingRight.replace('px', '')) +
          Number(computedStyle.marginLeft.replace('px', '')) +
          Number(computedStyle.marginRight.replace('px', ''));
        const heightMargin = Number(computedStyle.paddingTop.replace('px', '')) +
          Number(computedStyle.marginTop.replace('px', ''));

        setSize({
          width: Math.ceil(Math.max(minWidth, (containerRect.width - widthMargin) || minWidth)),
          height: Math.ceil(Math.max(minHeight, (avaialbleHeight - (containerRect.top + heightMargin) - 20) || minHeight))
        });
      } else {
        setSize({
          width: availableWidth,
          height: avaialbleHeight
        });
      }

    },
    [availableWidth, avaialbleHeight, minWidth, minHeight, containerRef]
  );

  return size;
}

export function useDebounced(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const timeoutId = setTimeout(() => setDebouncedValue(value), delay);

      return () => clearTimeout(timeoutId);
    },
    [value, delay]
  );

  return debouncedValue;
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(
    () => {
      ref.current = value;
    },
    [value]
  ); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export function useEscapePress(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        if (!!ref.current && ref.current.contains(event.target) && event.key === 'Escape') {
          handler(event);
        }
      };

      if (!!ref.current) {
        //const element = ref.current;
        document.addEventListener('keydown', listener);

        return () => {
          document.removeEventListener('keydown', listener);
        };
      }
    },
    [ref, handler]
  );
}


export function useWindowSize() {
  const isClient = useMemo(() => typeof window === 'object', []);

  const initialWindowSize = {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };

  const [windowSize, setWindowSize] = useState(initialWindowSize);

  useEffect(
    () => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(({
          width: isClient ? window.innerWidth : undefined,
          height: isClient ? window.innerHeight : undefined
        }));
      }

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    },
    [isClient]
  ); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}


export function useCancellablePromise() {
  const promises = useRef();

  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(
    () => {
      promises.current = promises.current || [];

      return function cancel() {
        promises.current.forEach(p => p.cancel());
        promises.current = [];
      };
    },
    []
  );

  return function cancellablePromise(p) {
    const cPromise = makeCancelable(p);

    promises.current.push(cPromise);

    return cPromise.promise;
  }
}

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history
    };
  }, [params, match, location, history]);
}

export function useScrollOnTopOnMount() {
  useEffect(() => window.scrollTo(0, 0), []);
}

export function useScrollOnTopOnChange(dep) {
  useEffect(() => window.scrollTo(0, 0), [dep]);
}

export function useOneOfTwo(one, two) {
  const [result, setResult] = useState(null);

  useEffect(() => { setResult(one); }, [one]);
  useEffect(() => { setResult(two); }, [two]);

  return result;
}

export function useWindowEvent(event, callback) {
  useEffect(() => {
    window.addEventListener(event, callback, true);

    return () => window.removeEventListener(event, callback, true);
  }, [event, callback]);
};

export function useElementEvent(element, event, callback) {
  useEffect(() => {
    !!element && element.addEventListener(event, callback, true);

    return () => {
      !!element && element.removeEventListener(event, callback, true);
    }
  }, [element, event, callback]);
};

export function useCurrentLocationCoordinates() {
  const [coordinates, setCoordinates] = useState([50.846056, 4.353358] /* Brusseles */);

  useEffect(
    () => {
      if (navigator.geolocation && (window.location.protocol === 'https' || window.location.hostname === 'localhost')) {
        navigator.geolocation.getCurrentPosition((pos) => setCoordinates([pos.coords.latitude, pos.coords.longitude]))
      }
    },
    []
  );

  return coordinates;
}