import React from 'react';
import styled from 'styled-components';

const LoadingElementStyled = styled.div`
  width: ${ /* sc-value */ ({ size }) => size}rem; 
  height: ${ /* sc-value */ ({ size }) => size}rem;
`;

export default function LoadingIndicator({ length = 5, type = 'grow' /* border|grow */, size = 3, className = '', style }) {
  return (
    <div className={`text-center d-flex justify-content-center ${className}`} style={style}>
      {[...Array(length).keys()].map(i => (
        <LoadingElementStyled
          key={`loading-element-${i}`}
          className={`spinner-${type}`}
          role="status"
          size={size}
        >
          <span className="sr-only">Loading...</span>
        </LoadingElementStyled>
      ))}
    </div>
  )
}