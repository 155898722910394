import HomeDescriptionPanel from './HomeDescriptionPanel';
import LibraryDescriptionPanel from './LibraryDescriptionPanel';
import ReadingListDescriptionPanel from './ReadingListDescriptionPanel';
import TopicsDescriptionPanel from './TopicsDescriptionPanel';
import AnalyticsDescriptionPanel from './AnalyticsDescriptionPanel';
import SocialMediaDescriptionPanel from './SocialMediaDescriptionPanel';
import ManageDescriptionPanel from './ManageDescriptionPanel';
import SearchDescriptionPanel from './SearchDescriptionPanel';

export default ({
  Home: HomeDescriptionPanel,
  Library: LibraryDescriptionPanel,
  ReadingLists: ReadingListDescriptionPanel,
  Topics: TopicsDescriptionPanel,
  Analytics: AnalyticsDescriptionPanel,
  SocialMedia: SocialMediaDescriptionPanel,
  Manage: ManageDescriptionPanel,
  Search: SearchDescriptionPanel
});