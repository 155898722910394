import React, { useRef, useCallback, useEffect, useState, forwardRef } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { noop, stopPreventEvent, eventTargetBlur } from '~/lib/Utils';

import { useDebounced } from '~/lib/Effects';


const AnimatedContainer = forwardRef(function ({ animate, children, ...props }, externalRef) {
  return (
    animate
      ? <div
        {...props}
        ref={externalRef}
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-duration="500"
      >
        {children}
      </div>
      : <div
        {...props}
        ref={externalRef}
      >
        {children}
      </div>
  );
});

export default function SearchControl({
  title = 'Search',
  className = '',
  query = '',
  debounce,
  animate = true,
  searchScopes,
  filters,
  onScopeToggle = noop,
  onQueryChange = noop,
  onForceQuery = noop }) {

  const queryRef = useRef(null);

  useEffect(() => {
    if (!!queryRef.current) {
      queryRef.current.focus();
    }
  }, [queryRef]);

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(query);
  }, [query]);

  const queryDebounced = useDebounced(value, debounce);

  useEffect(() => {
    onQueryChange(queryDebounced);
  }, [queryDebounced, onQueryChange]);

  const handleValueChange = useCallback(({ target: { value } }) => {
    setValue(value);
  }, []);

  const handleValueEnterPressed = useCallback(({ key }) => {
    if (key === 'Enter') {
      onForceQuery();
    }
  }, [onForceQuery]);

  const getHandleScopeClick = useGetCallback(
    (scope) =>
      (e) => {
        stopPreventEvent(e);
        eventTargetBlur(e);

        onScopeToggle(scope);
      },
    scope => scope,
    [onScopeToggle]
  );

  return (
    <div className={`w-100 ${className}`}>
      <AnimatedContainer
        animate={animate}
        className="rounded shadow mt-0 mb-4 text-center d-flex flex-column"
      >
        <div className="input-group input-group-lg">

          <div className="input-group-prepend">
            <span className="input-group-text border-0 pr-1">
              <i className="fe fe-search" />
            </span>
          </div>

          <input
            className="form-control form-control-lg border-0 px-1"
            type="text"
            placeholder="Type your text here"
            value={value || ''}
            autoFocus={true}
            ref={queryRef}
            onChange={handleValueChange}
            onKeyDown={handleValueEnterPressed}
          />

          <div className="input-group-append">
            <span className="input-group-text border-0 py-0 pl-1 pr-3">
              <button
                type="button"
                className="btn btn-sm btn-primary-soft lift"
                title="Search"
                onClick={onForceQuery}
              >
                {title}
              </button>
            </span>
          </div>

        </div>

        {!!searchScopes && !!filters && (
          <div className="d-flex pb-3 pl-8 bg-white">
            {searchScopes.map((scope, indx) => (
              <span
                key={`scope-${indx}`}
                className={`badge badge-pill badge-${!!filters.scope && filters.scope.some(s => s === scope) ? 'primary-soft' : 'secondary-soft bg-white'} mr-2 pointable`}
                onClick={getHandleScopeClick(scope)}
              >
                {scope}
              </span>
            ))}
          </div>
        )}
      </AnimatedContainer>
    </div>
  );
}