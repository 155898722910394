import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// Component
import { Link } from 'react-router-dom';

const LogoStyled = styled.img`
  max-height: 5rem;
  width: auto;
`;

const LogoGlobalOverrides = createGlobalStyle`
  .logo {
    outline: 0;
    border: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .logo::-moz-focus-inner { 
    border: 0; 
  }
`;

export default function Logo({ logo }) {
  return (
    <React.Fragment>
      <LogoGlobalOverrides />
      <Link to="/" className="navbar-brand logo">
        <LogoStyled src={logo} className="navbar-brand-img mr-3" alt="Semex.io" />
      </Link>
    </React.Fragment>
  );
}