import React, { useCallback, useMemo } from 'react';
import { addDays } from 'date-fns';

import { noop } from '~/lib/Utils';

// Components
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const calendarRangeOptions = {
  min: addDays(new Date(), -900),
  max: addDays(new Date(), 30)
};

export default function DateIntervalSelector({ value, onChange = noop }) {
  const range = useMemo(() => {
    return ({
      startDate: (!!value && value.from) || new Date(),
      endDate: (!!value && value.to) || new Date(),
      key: 'range'
    })
  }, [value]);

  const ranges = useMemo(() => {
    return [range];
  }, [range]);

  const handleDateChange = useCallback((item) => {
    const range = item[Object.keys(item)[0]]; // it can be range, range1, and so on... for some reasons

    onChange({
      from: range.startDate,
      to: range.endDate
    });
  }, [onChange]);

  return (
    <div>
      <DateRangePicker
        onChange={handleDateChange}
        months={1}
        minDate={calendarRangeOptions.min}
        maxDate={calendarRangeOptions.max}
        ranges={ranges}
      />
    </div>
  );
}