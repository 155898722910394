import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';


export default function PolarityScoreIntervalSelector({ value, intervals, onChange = noop }) {
  const handleIntervalChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!intervals && (
        <select className="form-control form-control-sm" value={`${value}`} onChange={handleIntervalChange}>
          {intervals.map((interval, indx) => (<option key={`interval-${indx}`} value={interval} >{interval}</option>))}
        </select>
      )}
    </React.Fragment>
  );
}