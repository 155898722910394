import React, { useCallback } from 'react';
import styled from 'styled-components';

import { is, stopPreventEvent } from '~/lib/Utils';

const WrappableStyled = styled.span`
  white-space: inherit;
`;

const DeleteFilterButtonStyled = styled.button`
  color: black;
  border-radius: 50%;
  line-height: 0.5;
  font-size: 0.5rem;
  padding: 2px;
  margin-left: 5px;
  margin-right: 0;
  background-color: white;
`;

const BadgeSpanWithButtonStyled = styled(WrappableStyled)`
  padding-right: 4px !important;
`;

const PointableSpanStyled = styled.span`
  cursor: pointer;
`;

function BadgeTitle({ title, value, formatFilterItem, className = '' }) {
  return (
    <span className={className}>
      {!is.nullOrEmptyString(title) && (<strong>{title}</strong>)}
      {is.notUndef(value) && (`${!is.nullOrEmptyString(title) ? ': ' : ''}${is.func(formatFilterItem) ? formatFilterItem(value) : value}`)}
    </span>
  )
}

function BadgePillButtonWithClose({
  title,
  value,
  onFilterRemove,
  onClick,
  formatFilterItem,
  badgeStyle = 'warning',
  closeButtonStyle = 'light',
  closeButtonTitle = 'Remove filter',
  className = ''
}) {

  const handleFilterRemove = useCallback((e) => {
    stopPreventEvent(e);

    if (is.func(onFilterRemove)) {
      onFilterRemove();
    }
  }, [onFilterRemove]);

  return (
    <BadgeSpanWithButtonStyled
      className={`badge badge-pill badge-${badgeStyle} mr-2 ${className} ${is.func(onClick) ? 'pointable' : 'cursor-default'}`}
      onClick={onClick}
    >
      {is.func(onFilterRemove) && (
        <DeleteFilterButtonStyled
          className={`btn btn-${closeButtonStyle} ml-2 float-right`}
          onClick={handleFilterRemove}
          title={closeButtonTitle}
        >
          <i className="fe fe-x" />
        </DeleteFilterButtonStyled>
      )}
      <BadgeTitle
        className={!is.func(onFilterRemove) ? 'mr-2' : ''}
        title={title}
        value={value}
        formatFilterItem={formatFilterItem}
      />
    </BadgeSpanWithButtonStyled>
  );
}

function BadgePillButton({
  title,
  value,
  onClick,
  formatFilterItem,
  badgeStyle = 'warning',
  className = '' }) {

  return (
    <PointableSpanStyled
      className={`badge badge-pill badge-${badgeStyle} text-nowrap mr-2 ${className} ${is.func(onClick) ? 'pointable' : ''}`}
      onClick={onClick}
    >
      <BadgeTitle
        title={title}
        value={value}
        formatFilterItem={formatFilterItem}
      />
    </PointableSpanStyled>
  );
}

function BadgePill({
  title,
  value,
  onClick,
  formatFilterItem,
  badgeStyle,
  className = '' }) {
  return (
    <span
      className={`badge badge-pill badge-${badgeStyle} text-nowrap mr-2 ${className} ${is.func(onClick) ? 'pointable' : ''}`}
    >
      <BadgeTitle
        title={title}
        value={value}
        formatFilterItem={formatFilterItem}
      />
    </span>
  );
}

export default ({
  BadgePillButtonWithClose,
  BadgePillButton,
  BadgePill
});