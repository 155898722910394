import React from 'react';
import { createGlobalStyle } from 'styled-components';

const FilterContainerGlobalOverrides = createGlobalStyle`
  .filter-control-container  {
    .form-control-sm {
      height: calc(1.9em + 2px) !important;
      padding: 0.1625rem 1rem !important;
      font-size: 80% !important;
    }
  }
`;

export default function FilterControlContainer({ title, children, highlight = false }) {
  return (
    <React.Fragment>
      <FilterContainerGlobalOverrides />
      <div className="card my-0 mx-0" >
        <div className="card-body py-2">
          <h4 className={`font-weight-bold small text-${highlight ? 'success' : 'secondary'}`}>{title}</h4>

          <div className="filter-control-container">
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}