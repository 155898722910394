import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { is, stopPreventEvent, openUrlNoOpener } from '~/lib/Utils';
import { useOnClickOutside } from '~/lib/Effects';


// Components
import { NavLink } from "react-router-dom";


export const MenuHoverDropdownGlobalStyle = createGlobalStyle`

  .navbar-nav .dropdown-hover-menu {
    float: none;
    display: block;
  }

  .dropdown-hover-menu .dropdown-img-left {
    height: initial;
  }

  .navbar-nav .dropdown-hover-menu .list-group-item.active {
    z-index: 2;
    background-color: #f1f4f8;
    border-color: #f1f4f8;
  }

  @media (min-width: 992px) {
    .navbar-nav .dropdown-hover-menu {
      transition: all .2s ease-in-out 1s;
      transition-property: opacity, transform;

      box-shadow: 0 1.5rem 4rem rgba(22,28,45,.15);
    }
  }

  @media (min-width: 992px) {
    .navbar-nav .dropdown-hover-menu {
      left: 50%;
      transform: translate(-50%, 10px);
    }
    .navbar-nav .dropdown-hover-menu.show {
      transform: translate(-50%, 0);
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-hover-menu {
      position: absolute;
    }
  }

  @media (min-width: 992px) {
    .navbar-nav .dropdown-hover-menu {
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;
        transition-property: opacity, transform;
    }
    .navbar-nav .dropdown-hover-menu.show {
        visibility: visible;
        opacity: 1;
    }
  }

  @media (max-width: 991.98px) {
    .navbar-collapse .navbar-nav > .nav-item .nav-link.dropdown {
        margin-bottom: 1.5rem;
        pointer-events: none;
    }
  }
`;

export default function NavigationItem({ id, label, img, icon, route, children, external = false }) {
  const [showPanelOnHover, setShowPanelOnHover] = useState(0);
  const [showPanelOnClick, setShowPanelOnClick] = useState(0);

  const handleMouseEnter = useCallback(() => {
    setShowPanelOnHover(s => s + 1);
  }, [setShowPanelOnHover]);

  const handleMouseLeave = useCallback(() => {
    setShowPanelOnHover(s => s - 1 < 0 ? 0 : s - 1);
  }, [setShowPanelOnHover]);

  const handlePanelDisable = useCallback(() => {
    setShowPanelOnHover(0);
  }, [setShowPanelOnHover]);

  const panelRef = useRef(null);

  useEffect(() => {
    let timeoutId = null;

    if (!!panelRef.current) {
      if (showPanelOnHover > 0) {
        timeoutId = setTimeout(() => {
          panelRef.current.classList.add('show');
        }, 500);
      } else {
        panelRef.current.classList.remove('show');
      }
    }

    return () => {
      if (!!timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [panelRef, showPanelOnHover]);


  const handleMenuLinkClick = useCallback((e) => {
    stopPreventEvent(e);

    if (!is.nullOrEmptyString(route)) {
      openUrlNoOpener(route);
    } else {
      setShowPanelOnClick(s => s + 1);
    }
  }, [route, setShowPanelOnClick]);

  const handlePanelBlur = useCallback(() => {
    setShowPanelOnClick(s => s - 1 < 0 ? 0 : s - 1);
  }, [setShowPanelOnClick]);

  useOnClickOutside(panelRef, handlePanelBlur);


  const location = useLocation();

  useEffect(() => {
    setShowPanelOnClick(s => s - 1 < 0 ? 0 : s - 1);
  }, [location, setShowPanelOnClick]);

  useEffect(() => {
    if (!!panelRef.current) {
      if (showPanelOnClick > 0) {
        panelRef.current.classList.add('show');
      } else {
        panelRef.current.classList.remove('show');
      }
    }
  }, [panelRef, showPanelOnClick]);


  const panel = useMemo(() => {
    if (!!children) {
      return React.cloneElement(children, {
        ref: panelRef,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      });
    }
  }, [children, handleMouseEnter, handleMouseLeave]);

  return (
    <li
      className="nav-item position-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handlePanelDisable}
    >
      {!external && !!route && !route.startsWith('http') ? (
        <NavLink
          exact={true}
          id={id}
          to={route}
          className={`nav-link ${!!panel ? 'dropdown' : ''}`}
        >
          {is.notUndef(img)
            ? <img src={img} alt={label} width="24" />
            : is.notUndef(icon)
              ? <i className={`fe fe-${icon}`} />
              : `${label}`
          }
        </NavLink>
      ) : (
          <a
            id={id}
            className={`nav-link ${!!panel ? 'dropdown' : ''}`}
            href="#!"
            onClick={handleMenuLinkClick}
          >
            {label}
          </a>
        )}
      {panel}
    </li>
  );
}