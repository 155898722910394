import { useState, useCallback } from "react";

export function useFilters(initialState) {
  const [filters, setFilters] = useState(initialState);

  const updateFilter = useCallback((name, value) => {
    setFilters(filters => ({ ...filters, [name]: !!value ? value : initialState[name] }));
  }, [initialState]);

  return ({
    filters,
    updateFilter
  })
}