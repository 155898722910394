import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';


export default function PolarityScoreScopeSelector({ value, scopes, onChange = noop }) {
  const handleScopeChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!scopes && (
        <select className="form-control form-control-sm" value={`${value}`} onChange={handleScopeChange}>
          {scopes.map((scope, indx) => (<option key={`scope-${indx}`} value={scope.code} >{scope.name}</option>))}
        </select>
      )}
    </React.Fragment>
  );
}