import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

export default function ShowSwitch({ id, checked, right, title, label = '', className = '', style, disabled = false, onShowChange = noop }) {

  const handleShowChange = useCallback(({ target: { checked } }) => {
    onShowChange(checked);
  }, [onShowChange]);


  return (
    <div
      className={`custom-control custom-switch ${right ? 'switch-right' : ''} ${className}`}
      title={title}
      style={style}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        className="custom-control-input pull-right"
        disabled={disabled}
        onChange={handleShowChange}
      />
      <label
        className="small custom-control-label"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}