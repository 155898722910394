import React, { useCallback } from 'react';

import { Store } from '~/store/StoreProvider';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import NavBar from '../components/App/NavBar';

export default function MainView({ children }) {
  const { state } = React.useContext(Store);
  const history = useHistory();
  const location = useLocation();

  const handleSearch = useCallback((query) => {
    history.push(`/search/q=${query}`);
  }, [history]);

  return (
    <React.Fragment>
      <NavBar
        user={state.user && state.user.data}
        showSearchBar={!location.pathname.startsWith('/search') && !location.pathname.startsWith('/login')}
        onSearch={handleSearch}
      />
      {children}
    </React.Fragment>
  );
}