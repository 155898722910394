import React, { useCallback, useState, useEffect } from 'react';

import { useDebounced } from '~/lib/Effects';
import { noop } from '~/lib/Utils';

export default function InputDebounced({ debounce = 500, value, onInputChange = noop, ...props }) {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(({ target: { value } }) => {
    setLocalValue(value);
  }, []);

  const valueDebounced = useDebounced(localValue, debounce);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onInputChange(valueDebounced);
  }, [valueDebounced, onInputChange]);

  return (
    <input {...props} value={localValue} onChange={handleChange} />
  )
}