import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useGetCallback } from 'react-cached-callback';
import styled from 'styled-components';

import { noop } from '~/lib/Utils';
import { useOnClickOutside, useEscapePress } from '~/lib/Effects';

// Components
import InputDebounced from './InputDebounced';
import { Collapse } from 'react-collapse';


const SearchInputStyled = styled.div`
  border-bottom: 1px solid var(--gray);
`;

const SearchSpinnerStyled = styled.div`
  transform: scale(0.8) !important;
  visibility: ${/* sc_value */ ({ show }) => show ? 'visible' : 'hidden'} !important;
`;


function PromptDebounced({
  value,
  placeholder = 'Start type to search',
  loading = false,
  onInputChange = noop,
  ...props
}) {

  return (
    <SearchInputStyled className="d-flex mb-2">
      <InputDebounced
        {...props}
        className="form-control form-control-sm border-0 pl-1 my-auto"
        value={value}
        placeholder={placeholder}
        onInputChange={onInputChange}
      />
      <SearchSpinnerStyled
        show={loading}
        className="spinner-grow text-primary my-auto ml-1 p-1"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </SearchSpinnerStyled>
    </SearchInputStyled>
  )
}

export default function SuggestionSelect({
  suggestions,
  loading = false,
  suggestionKey = 'id',
  placeholder,
  onInputChange = noop,
  onSuggestionSelect = noop,
  SuggestionComponent = <div />
}) {
  const containerRef = useRef(null);

  const [resultsExpanded, setResultsExpanded] = useState(false);

  const [value, setValue] = useState('');

  const handleInputChange = useCallback((value) => {
    setValue(val => {
      if (val !== value) {
        setResultsExpanded(false);
        onInputChange(value);
      }

      return value;
    });

  }, [onInputChange]);

  const handleInputFocus = useCallback(() => {
    setResultsExpanded(true);
  }, []);

  const getHandleTopicSelect = useGetCallback(
    (value) =>
      () => {
        onSuggestionSelect(value);
        setValue(value[suggestionKey]);
        setResultsExpanded(false);
      },
    value => value[suggestionKey],
    [onSuggestionSelect, suggestionKey]);

  const handleSearchControlCollapse = useCallback(() => {
    setResultsExpanded(false);
  }, []);

  useOnClickOutside(containerRef, handleSearchControlCollapse);
  useEscapePress(containerRef, handleSearchControlCollapse);


  useEffect(() => {
    setResultsExpanded(!!suggestions && !!suggestions.length && !loading);
  }, [suggestions, loading]);

  return (
    <div className="d-flex flex-column ml-2 my-2" ref={containerRef}>
      <PromptDebounced
        value={value}
        loading={loading}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        onFocus={handleInputFocus}
      />

      <Collapse isOpened={resultsExpanded}>
        {!!suggestions && suggestions.length && (
          <div className="list-group list-group-flash">
            {suggestions.map((suggestion, indx) =>
              <div
                key={`topic-suggestion-${indx}`}
                className="small my-0 py-2 px-2 d-flex w-100 justify-content-between pointable"
                onClick={getHandleTopicSelect(suggestion)}
              >
                <SuggestionComponent data={suggestion} />
              </div>
            )}
          </div>
        )}
      </Collapse>
    </div>
  );
}