import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { noop } from '~/lib/Utils';
import { useDebounced } from '~/lib/Effects';

// Components
import Autosuggest from 'react-autosuggest';
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

const renderSuggestion = contentType => (
  <div className="d-flex w-100 justify-content-between">
    <span>
      {contentType.name}
    </span>
    <span className="badge badge-primary-soft badge-pill">
      {contentType.count}
    </span>
  </div>
);

const renderInputComponent = inputProps => (
  <div>
    <input {...inputProps} className="form-control form-control-sm" />
  </div>
);

const getSuggestionValue = contentType => contentType.name;

const shouldRenderSuggestions = () => true;

export default function ContentTypeSelector({ contentTypes, value, placeholder = 'Type to search a content type...', onChange = noop }) {
  const [prompt, setPrompt] = useState(value);
  const [suggestions, setSuggestions] = useState(contentTypes);

  useEffect(() => {
    setPrompt(value);
  }, [value]);

  useEffect(() => {
    setSuggestions(contentTypes)
  }, [contentTypes]);

  const onSuggestionsFetchRequested = useCallback(({ value }) => {
    !!contentTypes && setSuggestions(contentTypes.filter(type => !value || type.name.toLowerCase().indexOf(value.toLowerCase()) >= 0));
  }, [contentTypes]);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const handleInputChange = useCallback((_e, { newValue }) => {
    setPrompt(newValue);
  }, []);

  const handleSuggestionSelected = useCallback((_e, { suggestionValue }) => {
    onChange(suggestionValue)
  }, [onChange]);

  const promptDebounced = useDebounced(prompt);

  useEffect(() => {
    onChange(promptDebounced);
  }, [promptDebounced, onChange]);

  const inputProps = useMemo(() => {
    return ({
      placeholder,
      value: prompt,
      onChange: handleInputChange
    });
  }, [prompt, placeholder, handleInputChange]);

  return (
    <React.Fragment>
      {!!contentTypes && !!contentTypes.length && !!suggestions ? (
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
          onSuggestionSelected={handleSuggestionSelected}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}
    </React.Fragment>
  );
}