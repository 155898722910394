import React from 'react';
import { useForm } from 'react-hook-form';

import { noop } from '~/lib/Utils';

import { validation } from '../model/validation';

export default function LogInForm({ standalone = false, onLogIn = noop }) {
  const { register, handleSubmit, errors, formState } = useForm();

  return (
    <React.Fragment>
      <div
        className={`mb-3 font-weight-bold text-center ${standalone ? 'h1' : 'h2'}`}
        id="modalSigninHorizontalTitle"
      >
        Sign In
      </div>

      <p className="mb-6 text-center text-muted">
        To access a library of sources and more.
      </p>

      <form
        className="mb-6 needs-validation"
        noValidate={true}
        onSubmit={handleSubmit(onLogIn)}
      >

        <div className="form-label-group">
          <input
            type="text"
            name="username"
            className={`form-control form-control-flush ${formState.isSubmitted ? !!errors.username ? 'is-invalid' : 'is-valid' : ''}`}
            id="modalSigninHorizontalUsername"
            placeholder="Username"
            ref={register(validation.login.username)}
          />
          <label htmlFor="modalSigninHorizontalUsername">
            Username
          </label>
        </div>

        <div className="form-label-group mb-5">
          <input
            type="password"
            name="password"
            className={`form-control form-control-flush ${formState.isSubmitted ? !!errors.password ? 'is-invalid' : 'is-valid' : ''}`}
            id="modalSigninHorizontalPassword"
            placeholder="Password"
            ref={register(validation.login.password)}
          />
          <label htmlFor="modalSigninHorizontalPassword">
            Password
          </label>
        </div>

        <button
          className="btn btn-block btn-primary"
          type="submit"
          disabled={formState.isSubmitting}
          formNoValidate={true}
        >
          Sign in
        </button>

      </form>
    </React.Fragment>
  );
}