import { useMemo, useCallback } from 'react';

import { stringToSnakeCase, formatDate, is } from '~/lib/Utils';

import { useFetch, useFetchDeferred } from '~/lib/Effects.Fetch';
import { parseQuery } from '~/model/Utils';

import { authorizeUrl, BaseURL } from '~/lib/Api';

export function useFetchSources(user, apiKey, filters, sort, start, rows) {
  const url = useMemo(() => {
    let url = `library/?format=json&offset=${start}&limit=${rows}`;

    url = authorizeUrl(url, user, apiKey);

    if (!!filters) {
      if (!!filters.language) {
        url += `&lang=${filters.language}`;
      }

      if (!!filters.region) {
        url += `&loc_admin_region=${encodeURIComponent(filters.region)}`;
      }

      if (!!filters.sourceSize && !!filters.sourceSize.constraint && !!filters.sourceSize.value) {
        url += `&size_src__${filters.sourceSize.constraint}=${filters.sourceSize.value * 1024}`
      }

      if (!!filters.sourceType) {
        if (filters.sourceType === 'sm') {
          url += `&src_type=${encodeURIComponent('feed:twitter:tweet')}`
        } else {
          url += `&src_type=${encodeURIComponent(filters.sourceType)}`
        }
      }

      if (!!filters.contentType) {
        url += (filters.contentType.toLowerCase() !== 'undefined')
          ? `&content_type__icontains=${encodeURIComponent(filters.contentType)}`
          : `&content_type__regex=${encodeURIComponent('\\W')}`
      }

      if (!!filters.owner) {
        url += `&owner__profile__organization__name__istartswith=${encodeURIComponent(filters.owner)}`;
      }

      if (!!filters.created) {
        if (!!filters.created.from) {
          url += `&created_at__gte=${formatDate(filters.created.from, 'yyyy-MM-dd')}T00:00:00`;
        }
        if (!!filters.created.to) {
          url += `&created_at__lte=${formatDate(filters.created.to, 'yyyy-MM-dd')}T23:59:59`;
        }
      }

      if (!!filters.updated) {
        if (!!filters.updated.from) {
          url += `&updated_at__gte=${formatDate(filters.updated.from, 'yyyy-MM-dd')}T00:00:00`;
        }
        if (!!filters.updated.to) {
          url += `&updated_at__lte=${formatDate(filters.updated.to, 'yyyy-MM-dd')}T23:59:59`;
        }
      }

      if (is.notUndef(filters.active)) {
        url += `&is_active=${filters.active}`
      }

      if (is.notUndef(filters.topics) && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
        url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
      }

    }

    if (!!sort && !!sort.property) {
      // convert owner.organization to owner__organization
      const sortProperty = sort.property.split('.').map(p => stringToSnakeCase(p)).join('__');

      url += `&order_by=${!sort.ascending ? '-' : ''}${sortProperty}`;
    }

    return url;
  }, [user, apiKey, filters, sort, start, rows]);

  const handleSourcesResponse = useCallback((response) => {
    return (!!response && ({ meta: response.meta, sources: response.objects })) || ({});
  }, []);

  return useFetch(url, handleSourcesResponse);
}

export function useFetchSource(id, user, apiKey, srcType = 'source') {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    if (srcType !== 'source') {
      return null;
    }

    let url = `library/${id}/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [user, apiKey, id, srcType]);

  return useFetch(url);
}

/**
 * called when user opens source details page from sources list (no paragraph or subparagraph),
 * or by clicking on a search result item (paragraph and subparagraph are set)
 * @param {string} id 
 * @param {string} query 
 * @param {object} filters 
 * @param {number} paragraph 
 * @param {number} subparagraph 
 * @param {object} user 
 * @param {string} apiKey 
 */
export function useFetchSourceParagraphs(id, query, filters, paragraph, subparagraph, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    let url = `search/?format=json&source_id=${id}&source_type=${encodeURIComponent('app:sources:LibrarySource')}&order_by=para_order&order_by=para_sub&size=1000&limit=20&offset=0`;

    url = authorizeUrl(url, user, apiKey);

    if (!!query && !!query.trim()) {
      const queryParsed = parseQuery(query);

      // if (!!queryParsed.topics.length) {
      //   url += queryParsed.topics.map(topic => `&topics=${encodeURIComponent(topic.trim())}`).join('');
      // }

      if (!!queryParsed.query) {
        url += `&query=${encodeURIComponent(queryParsed.query.trim())}`;
      }
    }

    if (!!filters) {
      if (is.notUndef(filters.polarity)) {
        if (is.number(filters.polarity) && filters.polarity !== 0) {
          url += `&polarity__${Math.sign(filters.polarity) > 0 ? 'gt' : 'lt'}=0`;
        } else if (is.array(filters.polarity) && filters.polarity.length === 2) {
          url += `&polarity__gte=${filters.polarity[0]}&polarity__lt=${filters.polarity[1]}`;
        }
      }

      if (is.notUndef(filters.topics) && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
        url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
      }
    }

    if (is.notUndef(paragraph)) {
      url += `&para_order__gte=${paragraph}`;

      if (is.notUndef(subparagraph)) {
        url += `&para_sub__gte=${subparagraph}`;
      }
    }

    return url;
  }, [id, query, filters, paragraph, subparagraph, user, apiKey]);

  return useFetch(url);
}

/**
 * called when user click on 'more' button on a filtered paragraph (after performing search by entity or text)
 * @param {string} id 
 * @param {number} order 
 * @param {object} user 
 * @param {string} apiKey 
 */
export function useFetchSourceFoundParagraphs(id, order, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    let url = `search/?format=json&source_id=${id}&source_type=${encodeURIComponent('app:sources:LibrarySource')}&order_by=para_order&order_by=para_sub&size=1000&limit=20&offset=0`;

    url = authorizeUrl(url, user, apiKey);

    if (is.notUndef(order)) {
      url += `&para_order__gte=${order}`;
    }

    return url;
  }, [id, order, user, apiKey]);

  return useFetch(url);
}

export function useFetchSourceSummary(user, apiKey) {
  const options = useMemo(() => {
    let url = `search/?format=json&source_type=${encodeURIComponent('app:sources:LibrarySource')}&para_order=-1&para_sub=0&size=1&limit=1&offset=0`;

    url = authorizeUrl(url, user, apiKey);

    return ({
      method: 'GET',
      path: url
    });

  }, [user, apiKey]);

  const handleSourceSummaryResponse = useCallback((response) => {
    return (!!response && !!response.objects.length && response.objects[0]) || null;
  }, []);

  return useFetchDeferred(options, handleSourceSummaryResponse)
}

/**
 * called when user opens reading list details
 * @param {string} id 
 * @param {object} user 
 * @param {string} apiKey 
 */
export function useFetchReadingListParagraphs(readingList, query, filters, user, apiKey) {
  const url = useMemo(() => {
    if (!readingList || !Array.isArray(readingList.summary) || !readingList.summary.length) {
      return null;
    }

    if (!user || !apiKey) {
      return null;
    }

    let url = `search/?format=json&source_id=${readingList.id}&source_type=${encodeURIComponent('app:readlst:ReadingList')}&order_by=para_order&order_by=para_sub&size=1000&limit=20&offset=0`;


    if (!!query && !!query.trim()) {
      const queryParsed = parseQuery(query);

      // if (!!queryParsed.topics.length) {
      //   url += queryParsed.topics.map(topic => `&topics=${encodeURIComponent(topic.trim())}`).join('');
      // }

      if (!!queryParsed.query) {
        url += `&query=${encodeURIComponent(queryParsed.query.trim())}`;
      }
    }

    if (!!filters) {
      if (is.notUndef(filters.topics) && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
        //
        // WARNING!!! query cannot be used with topics because search returns results of a different type, and will be ignored
        //
        //url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
        url = `search/?format=json&url={type:crl,id:${readingList.id},field:sources}&source_type__exact=${encodeURIComponent('app:sources:LibrarySource')}&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}&size=1000&limit=20&offset=0`
      }

      if (is.notUndef(filters.polarity)) {
        if (is.number(filters.polarity) && filters.polarity !== 0) {
          url += `&polarity__${Math.sign(filters.polarity) > 0 ? 'gt' : 'lt'}=0`;
        } else if (is.array(filters.polarity) && filters.polarity.length === 2) {
          url += `&polarity__gte=${filters.polarity[0]}&polarity__lt=${filters.polarity[1]}`;
        }
      }
    }

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [readingList, query, filters, user, apiKey]);

  return useFetch(url);
}

/**
 * called when user click on 'more' button on a filtered paragraph (after performing search by entity or text)
 * @param {string} id 
 * @param {number} order 
 * @param {object} user 
 * @param {string} apiKey 
 */
export function useFetchReadingListFoundParagraphs(sourceType, id, order, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    if (!user || !apiKey) {
      return null;
    }

    const sourceTypeId = sourceType === 'reading-list'
      ? 'app:readlst:ReadingList'
      : 'app:sources:LibrarySource';

    let url = `search/?format=json&source_id=${id}&source_type=${encodeURIComponent(sourceTypeId)}&order_by=para_order&order_by=para_sub&size=1000&limit=20&offset=0`;

    url = authorizeUrl(url, user, apiKey);

    if (is.notUndef(order)) {
      url += `&para_order__gte=${order}`;
    }

    return url;
  }, [sourceType, id, order, user, apiKey]);

  return useFetch(url);
}

export function useFetchContentTypes(user, apiKey) {
  const url = useMemo(() => {
    let url = `content_type/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [user, apiKey]);

  const handleContentTypesResponse = useCallback((response) => {
    return (!!response && response.objects) || [];
  }, []);

  return useFetch(url, handleContentTypesResponse)
}

export function useFetchSourceTypes(user, apiKey) {
  const url = useMemo(() => {
    let url = `source_type/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [user, apiKey]);

  const handleSourceTypesResponse = useCallback((response) => {
    return (!!response && response.objects) || [];
  }, []);

  return useFetch(url, handleSourceTypesResponse);
}

export function useFetchOrgarizations(user, apiKey) {
  const url = useMemo(() => {
    let url = `organization/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [user, apiKey]);

  const handleOrganizationsResponse = useCallback((response) => {
    return (!!response && response.objects) || null;
  }, []);

  return useFetch(url, handleOrganizationsResponse);
}

export function useFetchReadingListSuggestions(query) {
  const url = useMemo(() => {
    if (is.nullOrEmptyString(query)) {
      return null;
    }

    return `suggest/?format=json&limit=30&source_type=${encodeURIComponent('app:readlst:ReadingList')}&query=${encodeURIComponent(query)}`;
  }, [query]);

  const handleReadingListSuggestionsResponse = useCallback((response) => {
    return (!!response && response.objects) || null;
  }, []);

  return useFetch(url, handleReadingListSuggestionsResponse);
}

export function useFetchTopicSuggestions(language, query) {
  const url = useMemo(() => {
    if (is.nullOrEmptyString(query)) {
      return null;
    }

    return `suggest/?format=json&lang=${language}&limit=30&source_type=${encodeURIComponent('app:topic:Topic')}&query=${encodeURIComponent(query)}`;
  }, [language, query]);

  const handleTopicSuggestionsResponse = useCallback((response) => {
    return (!!response
      && !!response.objects
      && response.objects.reduce(
        (res, topic) => {
          if (!!res.find(r => r.text === topic.text && r.lang === topic.lang)) {
            return res;
          } else {
            return res.concat(topic)
          }
        }, [])) || null;
  }, []);

  return useFetch(url, handleTopicSuggestionsResponse);
}

export function usePostReadingList(user, apiKey) {
  const options = useMemo(() => {
    if (!user || !apiKey) {
      return null;
    }

    let url = `crl/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return ({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf8'
      },
      path: url,
      data: null
    })
  }, [user, apiKey]);

  return useFetchDeferred(options);
}

export function usePatchReadingList(user, apiKey) {
  const options = useMemo(() => {
    if (!user || !apiKey) {
      return null;
    }

    let url = `crl/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return ({
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf8'
      },
      path: url,
      data: null
    })
  }, [user, apiKey]);

  return useFetchDeferred(options);
}

export function useFetchReadingLists(user, apiKey, filters, sort, start, rows) {
  const url = useMemo(() => {
    let url = `crl/?format=json&offset=${start}&limit=${rows}`;

    url = authorizeUrl(url, user, apiKey);


    if (!!sort && !!sort.property) {
      // convert owner.organization to owner__organization
      const sortProperty = sort.property.split('.').map(p => stringToSnakeCase(p)).join('__');

      url += `&order_by=${!sort.ascending ? '-' : ''}${sortProperty}`;
    }

    if (!!filters) {
      if (is.notUndef(filters.active)) {
        url += `&is_active=${filters.active}`
      }

      if (is.notUndef(filters.topics) && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
        url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
      }
    }

    return url;
  }, [user, apiKey, filters, sort, start, rows]);

  const handleSourcesResponse = useCallback((response) => {
    return (!!response && ({ meta: response.meta, readingLists: response.objects })) || ({});
  }, []);

  return useFetch(url, handleSourcesResponse);
}

export function useFetchReadingList(id, user, apiKey, srcType) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    if (srcType === 'source') {
      return null;
    }

    let url = `crl/${id}/?format=json`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [id, user, apiKey, srcType]);

  return useFetch(url);
}

export function useFetchReadingListErrors(id, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    let url = `crl/${id}/?format=json&fields=name,errors`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [id, user, apiKey]);

  return useFetch(url);
}

export function useFetchReadingListWordCount(id, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    let url = `crl/${id}/?format=json&fields=name,stat`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [id, user, apiKey]);

  return useFetch(url);
}

export function useFetchReadingListPolarityScore(scope, id, interval, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    //let url = `search/?lang=en&source_id=${id}&source_type=${encodeURIComponent('app:readlst:ReadingList')}&size=0&agg__topics={terms:{field:${scope},size:20},aggs:{agg__polarity_scores:{histogram:{field:polarity,interval:${interval}}}}}`;
    let url = `search/?lang=en&source_type__exact=${encodeURIComponent('app:sources:LibrarySource')}&url={type:crl,id:${id},field:sources}&size=0&agg__topics={terms:{field:${scope},size:20},aggs:{agg__polarity_scores:{histogram:{field:polarity,interval:${interval}}}}}&para_order__gte=0`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [scope, id, interval, user, apiKey]);

  const handlePolarityScoreResponse = useCallback((response) => {
    return (!!response && !!response.aggregations && response.aggregations.aggTopics) || null;
  }, []);

  return useFetch(url, handlePolarityScoreResponse);
}

export function useFetchSourcePolarityScore(id, interval, user, apiKey) {
  const url = useMemo(() => {
    if (!id) {
      return null;
    }

    let url = `search/?lang=en&source_id=${id}&source_type=${encodeURIComponent('app:sources:LibrarySource')}&size=0&agg__topics={terms:{field:topics,size:20},aggs:{agg__polarity_scores:{histogram:{field:polarity,interval:${interval}}}}}&para_order__gte=0`;

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [id, interval, user, apiKey]);

  const handlePolarityScoreResponse = useCallback((response) => {
    return (!!response && !!response.aggregations && response.aggregations.aggTopics) || null;
  }, []);

  return useFetch(url, handlePolarityScoreResponse);
}

export function useGetDocxDownloadUrl(readingList, user, apiKey) {
  return useMemo(() => {
    if (!readingList) {
      return null;
    } else {
      return authorizeUrl(`${BaseURL}/resources/crl_as_docx/${readingList.id}/?`, user, apiKey);
    }
  }, [readingList, user, apiKey]);
}

export function useFetchRegions(user, apiKey) {
  const url = useMemo(() => {
    let url = 'region/?format=json';

    url = authorizeUrl(url, user, apiKey);

    return url;
  }, [user, apiKey]);

  const handleRegionsResponse = useCallback((response) => {
    return (!!response && response.objects) || null;
  }, []);

  return useFetch(url, handleRegionsResponse);
}