import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { useGetCallback } from 'react-cached-callback';

import { noop, is, polarityFormatted } from '~/lib/Utils';
import { getPolarityColor } from '~/model/Utils';

// components
import { Link } from 'react-router-dom';

const ResultHighlighterStyled = styled.span`
  em {
    background-color: var(--warning);
  }
`;

const PointableSpanStyled = styled.span`
  cursor: pointer;
`;

function Title({
  item: {
    text,
    sourceType,
    sourceId,
    paraOrder,
    paraSub
  },
  crlSelected,
  crlEnable,
  onCrlSourceSelect
}) {

  const sourceIcon = useMemo(() => {
    switch (sourceType.toLowerCase()) {
      case 'feed:twitter:tweet':
        return <i className="font-size-lg mr-2 fe fe-twitter" />;
      case 'app:sources:librarysource':
        return <i className="font-size-lg mr-2 fe fe-layers" />;
      case 'app:readlst:readinglist':
        return <i className="font-size-lg mr-2 fe fe-list" />
      default:
        return null;
    }
  }, [sourceType]);

  const Wrapper = useMemo(() => {
    return ({ children }) =>
      sourceType.toLowerCase() === 'app:sources:librarysource'
        ? <Link
          to={`/library/source/${sourceId}${is.notUndef(paraOrder) || is.notUndef(paraSub) ? `/${paraOrder || 0}/${paraSub || 0}` : ''}`}
        >
          {children}
        </Link>
        : sourceType.toLowerCase() === 'app:readlst:readinglist'
          ? <Link
            to={`/reading-list/${sourceId}`}
          >
            {children}
          </Link>
          : < span > {children}</span >
  }, [sourceType, sourceId, paraOrder, paraSub]);

  return (
    <React.Fragment>
      {sourceIcon}

      {sourceType.toLowerCase() === 'app:sources:librarysource' && crlEnable && (
        <span
          className={`btn btn-sm btn-outline-${crlSelected ? 'success' : 'secondary'} btn-pill py-0 px-1 mr-2 ${crlSelected ? 'disabled' : ''}`}
          title={!crlSelected ? 'Select to add to Curated Reading List' : ''}
          onClick={onCrlSourceSelect}
        >
          <i className={`fe fe-${crlSelected ? 'check' : 'plus'}-circle mr-2`} />
          <span className="small font-weight-bold align-text-top">CRL</span>
        </span>
      )}

      <Wrapper>
        {!!text && text.map((t, indx) => (
          <ResultHighlighterStyled key={`text-${indx}`} className="mr-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t) }} />
        ))}
      </Wrapper>
    </React.Fragment>
  );
}

export default function SearchResultItem({
  item,
  crlSelected,
  crlEnable,
  onTopicSelect = noop,
  onCrlSourceSelect
}) {

  const polarityColor = useMemo(() => {
    return getPolarityColor(item.polarity);
  }, [item]);

  const getHandleTopicClick = useGetCallback(
    (topic) =>
      () => {
        onTopicSelect(topic);
      },
    topic => topic,
    [onTopicSelect]);

  return (
    <React.Fragment>
      <p className="font-weight-bold mb-1">
        <Title
          item={item}
          crlSelected={crlSelected}
          crlEnable={crlEnable}
          onCrlSourceSelect={onCrlSourceSelect}
        />
      </p>

      {!!item.polarity && (
        <div>
          <h6 className={`text-uppercase text-${polarityColor} d-inline-block mb-2 mr-1`}>Polarity:</h6>
          <span className={`badge badge-rounded-circle badge-${polarityColor}-soft px-2`}>{polarityFormatted(item.polarity)}</span>
        </div>
      )}

      {!!item.url && (
        <p className="mb-1">
          <a className="dont-break-out" href={item.url} rel="noopener noreferrer" target="_blank">{item.url}</a>

          {!!item.lang && (
            <span className="text-uppercase ml-1">({item.lang})</span>
          )}
        </p>
      )}

      <p className={`font-size-sm text-muted mb-${!!item.topics ? '1' : '0'}`}>
        {!!item.locCountrycode && (
          <img className="mr-1" src={`/assets/img/flags100px/${item.locCountrycode.toLowerCase()}.png`} width="24" alt={item.locCountry} />
        )}
        {!!item.locName && (
          <span className="mr-1">{item.locName}</span>
        )}
        {!!item.locCoords && (
          <span>
            <i className="mr-1 fe fe-map-pin" />
            {item.locCoords.lat}, {item.locCoords.lon}
          </span>
        )}
      </p>

      {!!item.topics && (
        <p className="mb-0">
          {item.topics.take(10).map((topic, indx) => (
            <PointableSpanStyled
              key={`topic-${indx}`}
              className="badge badge-pill badge-primary-soft mr-1"
              onClick={getHandleTopicClick(topic)}
            >
              {topic}
            </PointableSpanStyled>
          ))}
        </p>
      )}
    </React.Fragment>
  );
}