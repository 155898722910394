import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import { noop } from '~/lib/Utils';

import { SourceSizeConstraints } from '~/modules/library/components/FiltersPanel/Utils.Filter';

// Components
import FilterControlContainer from '~/modules/library/components/FiltersPanel/FilterControlContainer';
import ContentTypeSelector from '~/modules/library/components/FilterModal/ContentTypeSelector';
import SourceTypeSelector from '~/modules/library/components/FilterModal/SourceTypeSelector';
import SourceSizeSelector from '~/modules/library/components/FilterModal/SourceSizeSelector';
import LanguageSelector from '~/modules/library/components/FilterModal/LanguageSelector';
import RegionSelector from '~/modules/library/components/FilterModal/RegionSelector';
import OrganizationSelector from '~/modules/library/components/FilterModal/OrganizationSelector';
import DateIntervalSelector from '~/modules/library/components/FilterModal/DateIntervalSelector';
import TopicSelector from '~/modules/library/components/FilterModal/TopicSelector';
import ObjectTypeSelector from './ObjectTypeSelector';
import PolarityScoreIntervalSelector from './PolarityScoreIntervalSelector';
import PolarityScoreScopeSelector from './PolarityScoreScopeSelector';
import PolaritySelector from '~/modules/library/components/FilterModal/PolaritySelector';

const DateVerticalStyled = styled.div`
  width: 100%;

  @media(min-width: 992px){
    .rdrDateRangePickerWrapper {
      display: flex !important;
      flex-direction: column-reverse;
    }
    
    .rdrDefinedRangesWrapper {
      width: 100% !important;
    }
  }
`;

export default function FiltersContainer({
  className = '',
  filters,
  languages,
  contentTypes,
  organizations,
  sourceTypes,
  objectTypes,
  regions,
  polarityScoreIntervals,
  polarityScoreScopes,
  showInterval,
  showTopics = true,
  showPolarity = true,
  showOwner = true,
  showContentType = true,
  showSourceType = true,
  showObjectType = true,
  showSourceSize = true,
  showPolarityScopes,
  topics,
  topicsLoading,
  onTopicsFetch,
  onFilterChange = noop
}) {

  const handleLanguageChange = useCallback((language) => {
    onFilterChange('language', language);
  }, [onFilterChange]);

  const [sourceSize, setSourceSize] = useState({
    constraint: '',
    value: 0
  });

  useEffect(() => {
    onFilterChange('sourceSize', sourceSize);
  }, [sourceSize, onFilterChange]);

  const handleSourceSizeConstraintChange = useCallback((constraint) => {
    setSourceSize(sourceSize => ({ ...sourceSize, constraint }));
  }, []);

  const handleSourceSizeValueChange = useCallback((value) => {
    setSourceSize(sourceSize => ({ ...sourceSize, value: Number(value) }));
  }, []);

  const handleObjectTypeChange = useCallback((objectType) => {
    onFilterChange('objectType', objectType);
  }, [onFilterChange]);

  const handleSourceTypeChange = useCallback((sourceType) => {
    onFilterChange('sourceType', sourceType);
  }, [onFilterChange]);

  const handleContentTypeChange = useCallback((contentType) => {
    onFilterChange('contentType', contentType);
  }, [onFilterChange]);

  const handleOwnerChange = useCallback((owner) => {
    onFilterChange('owner', owner);
  }, [onFilterChange]);

  const handledCreatedChange = useCallback((created) => {
    onFilterChange('created', created);
  }, [onFilterChange]);

  const handledUpdatedChange = useCallback((updated) => {
    onFilterChange('updated', updated);
  }, [onFilterChange]);

  const handleTopicsChange = useCallback((topics) => {
    onFilterChange('topics', topics);
  }, [onFilterChange]);

  const handlePolarityIntervalChange = useCallback((interval) => {
    onFilterChange('polarityInterval', interval);
  }, [onFilterChange]);

  const handlePolarityScopeChange = useCallback((scope) => {
    onFilterChange('polarityScope', scope);
  }, [onFilterChange]);

  const handlePolarityChange = useCallback((polarity) => {
    onFilterChange('polarity', polarity);
  }, [onFilterChange]);

  const handleRegionChange = useCallback((region) => {
    onFilterChange('region', region);
  }, [onFilterChange]);

  return (
    <div className={`d-flex flex-column px-0 ${className}`}>
      <FilterControlContainer title="Language">
        <LanguageSelector
          languages={languages}
          value={filters.language}
          onChange={handleLanguageChange}
        />
      </FilterControlContainer>

      {!!showTopics && (
        <FilterControlContainer title="Topics">
          <TopicSelector
            topics={topics}
            loading={topicsLoading}
            value={filters.topics}
            onTopicsFetch={onTopicsFetch}
            onChange={handleTopicsChange}
          />
        </FilterControlContainer>
      )}

      {!!showPolarityScopes && (
        <FilterControlContainer title="Polarity scope">
          <PolarityScoreScopeSelector
            scopes={polarityScoreScopes}
            value={filters.polarityScope}
            onChange={handlePolarityScopeChange}
          />
        </FilterControlContainer>
      )}

      {!!showInterval && (
        <FilterControlContainer title="Polarity interval">
          <PolarityScoreIntervalSelector
            intervals={polarityScoreIntervals}
            value={filters.polarityInterval}
            onChange={handlePolarityIntervalChange}
          />
        </FilterControlContainer>
      )}

      {!!showPolarity && (
        <FilterControlContainer title="Polarity">
          <PolaritySelector
            value={filters.polarity}
            onChange={handlePolarityChange}
          />
        </FilterControlContainer>
      )}

      {!!showOwner && (
        <FilterControlContainer title="Owner">
          <OrganizationSelector
            organizations={organizations}
            value={filters.owner}
            onChange={handleOwnerChange}
          />
        </FilterControlContainer>
      )}

      <FilterControlContainer title="Region">
        <RegionSelector
          regions={regions}
          value={filters.region}
          onChange={handleRegionChange}
        />
      </FilterControlContainer>

      {!!showObjectType && (
        <FilterControlContainer title="Object type">
          <ObjectTypeSelector
            objectTypes={objectTypes}
            value={filters.objectType}
            onChange={handleObjectTypeChange}
          />
        </FilterControlContainer>
      )}

      {!!showSourceType && filters.objectType.toLowerCase() !== 'app:readlst:readinglist' && (
        <FilterControlContainer title="Source type">
          <SourceTypeSelector
            sourceTypes={sourceTypes}
            value={filters.sourceType}
            onChange={handleSourceTypeChange}
          />
        </FilterControlContainer>
      )}

      {!!showSourceSize && filters.objectType.toLowerCase() !== 'app:readlst:readinglist' && (
        <FilterControlContainer title="Source size">
          <SourceSizeSelector
            constraints={SourceSizeConstraints}
            value={filters.sourceSize}
            onConstraintChange={handleSourceSizeConstraintChange}
            onValueChange={handleSourceSizeValueChange}
          />
        </FilterControlContainer>
      )}

      {!!showContentType && (
        <FilterControlContainer title="Content type">
          <ContentTypeSelector
            contentTypes={contentTypes}
            value={filters.contentType}
            onChange={handleContentTypeChange}
          />
        </FilterControlContainer>
      )}

      <DateVerticalStyled>
        <FilterControlContainer title="Created date">
          <DateIntervalSelector
            value={filters.created}
            onChange={handledCreatedChange}
          />
        </FilterControlContainer>
      </DateVerticalStyled>

      <DateVerticalStyled>
        <FilterControlContainer title="Updated date">
          <DateIntervalSelector
            value={filters.updated}
            onChange={handledUpdatedChange}
          />
        </FilterControlContainer>
      </DateVerticalStyled >
    </div>
  );
}