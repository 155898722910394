import { updateState, loadState, makePersistent } from '~/lib/Utils.Store.js';

import LanguageSeeds from '~/data/topics.json';

const lang = localStorage.getItem('lang') || 'en';

export const initialState = {
  explorer: loadState('state.explorer', {
    topics: {
      root: LanguageSeeds[lang],
      rootLanguage: lang,
      depth: 2,
      chartType: 'horizontal',
      chartRotation: 0,
      chartDensity: 'normal',
      chartSplit: 'both',
      historyTopics: [/* topic */],
      documentTopics: [/* {topic, [documents], expanded} */]
    },
    keywords: {
      depth: 2,
      chartType: 'horizontal',
      chartRotation: 0,
      chartDensity: 'normal',
      chartSplit: 'both',
      historyTopics: {/* modelId: [topic] */ }
    }
  })
};

export const symbiotes = makePersistent('state.explorer', state => state.explorer, {
  explorer: {
    topics: {
      rootChange: (state, root) => updateState(state, ['explorer', 'topics'], { root }),
      rootLanguageChange: (state, rootLanguage) => updateState(state, ['explorer', 'topics'], { rootLanguage }),
      depthChange: (state, depth) => updateState(state, ['explorer', 'topics'], { depth }),
      chartTypeChange: (state, chartType) => updateState(state, ['explorer', 'topics'], { chartType }),
      chartRotationChange: (state, chartRotation) => updateState(state, ['explorer', 'topics'], { chartRotation }),
      chartDensityChange: (state, chartDensity) => updateState(state, ['explorer', 'topics'], { chartDensity }),
      chartSplitChange: (state, chartSplit) => updateState(state, ['explorer', 'topics'], { chartSplit }),
      historyTopicsChange: (state, historyTopics) => updateState(state, ['explorer', 'topics'], { historyTopics }),
      historyTopicsAdd: (state, topic) => updateState(
        state,
        ['explorer', 'topics'],
        { historyTopics: state.explorer.topics.historyTopics.filter(t => t !== topic).takeRight(9).concat(topic) }
      ),
      historyTopicsDelete: (state, topicIndex) => updateState(
        state,
        ['explorer', 'topics'],
        { historyTopics: state.explorer.topics.historyTopics.filter((_i, indx) => indx !== topicIndex) }
      ),
      historyTopicsClear: (state) => updateState(state, ['explorer', 'topics'], { historyTopics: [] }),
      documentTopicsChange: (state, documentTopics) => updateState(state, ['explorer', 'topics'], { documentTopics }),
      documentTopicsAdd: (state, topic) => updateState(
        state,
        ['explorer', 'topics'],
        {
          documentTopics:
            state.explorer.topics.documentTopics
              .filter(t => t.topic !== topic.topic)
              .takeRight(4)
              .map(t => ({ ...t, expanded: false }))
              .concat({ ...topic, expanded: true })
        }
      ),
      documentTopicsDelete: (state, topicIndex) => updateState(
        state,
        ['explorer', 'topics'],
        { documentTopics: state.explorer.topics.documentTopics.filter((_i, indx) => indx !== topicIndex) }
      ),
      documentTopicsClear: (state) => updateState(state, ['explorer', 'topics'], { documentTopics: [] }),
      documentTopicExpandedChange: (state, topicIndex, status) => updateState(
        state,
        ['explorer', 'topics'],
        {
          documentTopics: state.explorer.topics.documentTopics
            .take(topicIndex)
            .concat({ ...state.explorer.topics.documentTopics[topicIndex], expanded: status })
            .concat(state.explorer.topics.documentTopics.takeRight(state.explorer.topics.documentTopics.length - topicIndex - 1))
        })
    },
    keywords: {
      depthChange: (state, depth) => updateState(state, ['explorer', 'keywords'], { depth }),
      chartTypeChange: (state, chartType) => updateState(state, ['explorer', 'keywords'], { chartType }),
      chartRotationChange: (state, chartRotation) => updateState(state, ['explorer', 'keywords'], { chartRotation }),
      chartDensityChange: (state, chartDensity) => updateState(state, ['explorer', 'keywords'], { chartDensity }),
      chartSplitChange: (state, chartSplit) => updateState(state, ['explorer', 'keywords'], { chartSplit }),
      historyTopicsChange: (state, modelId, historyTopics) => updateState(
        state,
        ['explorer', 'keywords'],
        {
          historyTopics: {
            ...state.explorer.keywords.historyTopics,
            [modelId]: historyTopics
          }
        }
      ),
      historyTopicsAdd: (state, modelId, topic) => updateState(
        state,
        ['explorer', 'keywords'],
        {
          historyTopics: {
            ...state.explorer.keywords.historyTopics,
            [modelId]: (state.explorer.keywords.historyTopics[modelId] || []).filter(t => t !== topic).takeRight(9).concat(topic)
          }
        }
      ),
      historyTopicsDelete: (state, modelId, topicIndex) => updateState(
        state,
        ['explorer', 'keywords'],
        {
          historyTopics: {
            ...state.explorer.keywords.historyTopics,
            [modelId]: (state.explorer.keywords.historyTopics[modelId] || []).filter((_i, indx) => indx !== topicIndex)
          }
        }
      ),
      historyTopicsClear: (state, modelId) => updateState(state, ['explorer', 'keywords'], { historyTopics: [] }),
    }
  }
});