import { updateState } from '~/lib/Utils.Store.js';

export const initialState = {
  user: {
    token: localStorage.getItem('user.access_token') || null,
    data: null,
    apiKey: null,
  }
};

export const symbiotes = {
  user: {
    dataChange: (state, data) => updateState(state, ['user'], { data }),
    tokenChange: (state, token) => {
      localStorage.setItem('user.access_token', token);

      return updateState(state, ['user'], { token });
    },
    dataClear: (state) => updateState(state, ['user'], { data: null }),
    dataRefresh: (state) => updateState(state, ['user'], { data: { ...state.user.data } }), // this will invalidate all user related hooks
    apiKeyChange: (state, apiKey) => updateState(state, ['user'], { apiKey }),
  }
};