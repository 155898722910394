import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';

import { noop } from '~/lib/Utils';
import { useOnClickOutside } from '~/lib/Effects';

const SearchBarStyled = styled.div`
  position: absolute;
  bottom: -20px;
  right: 20px;
  background-color: rgba(51,94,234,.15);
  padding-right: 0.5rem !important;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  transition: all 0.25s ease-out !important;

  .col-8 {
    flex-grow: 1;
  }

  .col-2 {
    width: 0;
    flex-shrink: 1;
  }
`;

const SearchInputStyled = styled.input`
  height: calc(1.9rem + 2px) !important;
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primary);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--primary);
  }
`;

export default function SearchBar({ onSearch = noop }) {
  const [expanded, setExpanded] = useState(false);

  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);
  const searchButtonRef = useRef(null);

  const handleInputFocus = useCallback(() => {
    setExpanded(true);
    if (!!inputRef.current) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [inputRef]);

  const handleContainerBlur = useCallback((e) => {
    if (!!searchButtonRef.current && e.currentTarget === searchButtonRef.current) {
      return;
    }
    setExpanded(false);
  }, [searchButtonRef]);

  const [query, setQuery] = useState('');

  const makeSearch = useCallback(() => {
    if (!!query && !!query.trim()) {
      setQuery('');
      if (!!inputRef.current) {
        inputRef.current.blur();
      }
      onSearch(query);
    }
  }, [query, inputRef, onSearch]);

  const handleSearchClick = useCallback(() => {
    makeSearch();
  }, [makeSearch]);

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setQuery(value);
  }, []);

  const handleSearchEnterPressed = useCallback(({ key }) => {
    if (key === 'Enter') {
      makeSearch();
    }
  }, [makeSearch]);

  useOnClickOutside(searchContainerRef, handleContainerBlur);

  return (
    <SearchBarStyled
      className={`text-primary d-flex align-items-start justify-content-between pr-1 pl-2 pt-2 pb-2 ${expanded ? 'col-8' : 'col-2'}`}
    >
      <div className="m-0 w-100">
        <span
          ref={searchContainerRef}
          className="input-group input-group-sm"
        >
          <img
            src="/assets/img/icons/duotone-icons/General/Search.svg"
            width="36"
            alt="Search"
            className="input-group-prepend mr-2"
          />
          <SearchInputStyled
            ref={inputRef}
            className="form-control form-control-lg border-0 text-primary bg-transparent px-1 py-1 w-100"
            placeholder="Search SEMEX"
            onFocus={handleInputFocus}
            onChange={handleSearchChange}
            onKeyDown={handleSearchEnterPressed}
          />
          {expanded && (
            <div className="input-group-append">
              <span className="input-group-text border-0 p-0 m-0 bg-transparent">
                <button
                  ref={searchButtonRef}
                  type="button"
                  className="btn btn-sm btn-primary py-1 lift"
                  title="Search"
                  style={{ height: '2rem' }}
                  onClick={handleSearchClick}
                >
                  Search
                </button>
              </span>
            </div>
          )}
        </span>
      </div>
    </SearchBarStyled>
  )
}
