import { useMemo } from 'react';

import { formatDate, is } from '~/lib/Utils';
import { parseQuery } from '../../../model/Utils';

import { useFetch, useFetchDeferred } from '~/lib/Effects.Fetch';

import { authorizeUrl } from '~/lib/Api';

export function useFetchSearch(query, filters) {
  const url = useMemo(() => {
    if (!!query && !!query.trim()) {
      let url = `search/?format=json&limit=10&offset=0`;

      if (!!query && !!query.trim()) {
        const queryParsed = parseQuery(query);

        if (!!queryParsed.topics.length) {
          url += queryParsed.topics.map(topic => `&topics=${encodeURIComponent(topic.trim())}`).join('');
        }

        if (!!queryParsed.query) {
          url += `&query=${encodeURIComponent(queryParsed.query.trim())}`;
        }
      }

      if (!!filters) {
        if (!!filters.language) {
          url += `&lang=${filters.language}`;
        }

        if (!!filters.region) {
          url += `&loc_admin_region=${encodeURIComponent(filters.region)}`;
        }

        if (!!filters.sourceSize && !!filters.sourceSize.constraint && !!filters.sourceSize.value) {
          url += `&size_src__${filters.sourceSize.constraint}=${filters.sourceSize.value * 1024}`
        }

        if (!!filters.objectType) {
          url += `&source_type=${encodeURIComponent(filters.objectType)}`
        }

        if (!!filters.sourceType && (!filters.objectType || filters.objectType.toLowerCase() !== 'app:readlst:readinglist')) {
          if (filters.sourceType === 'sm') {
            url += `&src_type=${encodeURIComponent('feed:twitter:tweet')}`
          } else {
            url += `&src_type=${encodeURIComponent(filters.sourceType)}`
          }
        }

        if (!!filters.contentType) {
          url += (filters.contentType.toLowerCase() !== 'undefined')
            ? `&content_type__icontains=${encodeURIComponent(filters.contentType)}`
            : `&content_type__regex=${encodeURIComponent('\\W')}`
        }

        if (!!filters.topics && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
          url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
        }

        if (!!filters.owner) {
          url += `&owner__profile__organization__name__istartswith=${encodeURIComponent(filters.owner)}`;
        }

        if (!!filters.created) {
          if (!!filters.created.from) {
            url += `&created_at__gte=${formatDate(filters.created.from, 'yyyy-MM-dd')}T00:00:00`;
          }
          if (!!filters.created.to) {
            url += `&created_at__lte=${formatDate(filters.created.to, 'yyyy-MM-dd')}T23:59:59`;
          }
        }

        if (!!filters.updated) {
          if (!!filters.updated.from) {
            url += `&updated_at__gte=${formatDate(filters.updated.from, 'yyyy-MM-dd')}T00:00:00`;
          }
          if (!!filters.updated.to) {
            url += `&updated_at__lte=${formatDate(filters.updated.to, 'yyyy-MM-dd')}T23:59:59`;
          }
        }

        if (!!filters.scope) {
          filters.scope.forEach(scope => {
            url += `&match=${scope}`;
          });
        }

        if (is.notUndef(filters.polarity)) {
          if (is.number(filters.polarity) && filters.polarity !== 0) {
            url += `&polarity__${Math.sign(filters.polarity) > 0 ? 'gt' : 'lt'}=0`;
          } else if (is.array(filters.polarity) && filters.polarity.length === 2) {
            url += `&polarity__gte=${filters.polarity[0]}&polarity__lte=${filters.polarity[1]}`;
          }
        }
      }

      return url;
    } else {
      return null;
    }
  }, [query, filters]);

  return useFetch(url);
}

export function useFetchSearchDeferred(query, filters, user, apiKey) {
  const options = useMemo(() => {
    let url = `search/?format=json&limit=10&offset=0`;

    url = authorizeUrl(url, user, apiKey);

    if (!!query && !!query.trim()) {
      const queryParsed = parseQuery(query);

      if (!!queryParsed.topics.length) {
        url += queryParsed.topics.map(topic => `&topics=${encodeURIComponent(topic.trim())}`).join('');
      }

      if (!!queryParsed.query) {
        url += `&query=${encodeURIComponent(queryParsed.query.trim())}`;
      }
    }

    if (!!filters) {
      if (!!filters.language) {
        url += `&lang=${filters.language}`;
      }

      if (!!filters.region) {
        url += `&loc_admin_region=${encodeURIComponent(filters.region)}`;
      }

      if (!!filters.sourceSize && !!filters.sourceSize.constraint && !!filters.sourceSize.value) {
        url += `&size_src__${filters.sourceSize.constraint}=${filters.sourceSize.value * 1024}`
      }

      if (!!filters.objectType) {
        url += `&object_type=${encodeURIComponent(filters.objectType)}`
      }

      if (!!filters.sourceType && (!filters.objectType || filters.objectType.toLowerCase() !== 'app:readlst:readinglist')) {
        url += `&src_type=${encodeURIComponent(filters.sourceType)}`
      }

      if (!!filters.contentType) {
        url += (filters.contentType.toLowerCase() !== 'undefined')
          ? `&content_type__icontains=${encodeURIComponent(filters.contentType)}`
          : `&content_type__regex=${encodeURIComponent('\\W')}`
      }

      if (!!filters.topics && Array.isArray(filters.topics.topics) && !!filters.topics.topics.length) {
        url += `&topics__${filters.topics.constraint}=${filters.topics.topics.map(t => encodeURIComponent(t)).join('|')}`;
      }

      if (!!filters.owner) {
        url += `&owner__profile__organization__name__istartswith=${encodeURIComponent(filters.owner)}`;
      }

      if (!!filters.created) {
        if (!!filters.created.from) {
          url += `&created_at__gte=${formatDate(filters.created.from, 'yyyy-MM-dd')}T00:00:00`;
        }
        if (!!filters.created.to) {
          url += `&created_at__lte=${formatDate(filters.created.to, 'yyyy-MM-dd')}T23:59:59`;
        }
      }

      if (!!filters.updated) {
        if (!!filters.updated.from) {
          url += `&updated_at__gte=${formatDate(filters.updated.from, 'yyyy-MM-dd')}T00:00:00`;
        }
        if (!!filters.updated.to) {
          url += `&updated_at__lte=${formatDate(filters.updated.to, 'yyyy-MM-dd')}T23:59:59`;
        }
      }

      if (!!filters.scope) {
        filters.scope.forEach(scope => {
          url += `&match=${scope}`;
        });
      }

      if (is.notUndef(filters.polarity)) {
        if (is.number(filters.polarity) && filters.polarity !== 0) {
          url += `&polarity__${Math.sign(filters.polarity) > 0 ? 'gt' : 'lt'}=0`;
        } else if (is.array(filters.polarity) && filters.polarity.length === 2) {
          url += `&polarity__gte=${filters.polarity[0]}&polarity__lt=${filters.polarity[1]}`;
        }
      }
    }

    return ({
      method: 'GET',
      path: url
    });
  }, [query, filters, user, apiKey]);

  return useFetchDeferred(options);
}