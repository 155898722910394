import React, { useCallback, useContext } from 'react';

import { useRouter } from '~/lib/Effects';
import { useNotifications, NotificationType } from '~/lib/Effects.Notify';

import { Store, actions } from '~/store/StoreProvider';

import {
  usePostUserLogIn
} from '../api';

// Components
import LogInForm from '../components/LogInForm';

export default function LogInPage() {
  const { dispatch } = useContext(Store);
  const notify = useNotifications();

  const { history, location } = useRouter();

  const redirectToReferrer = useCallback((_username) => {
    if (!!history) {
      if (!!location && !!location.state && !!location.state.from) {
        history.replace(location.state.from);
      } else {
        history.replace('/');
      }
    }
  }, [history, location]);


  const getUsePostUserLogIn = usePostUserLogIn();

  const handleLogIn = useCallback(async ({ username, password }) => {
    const result = await getUsePostUserLogIn({ data: { username, password } })

    if (!!result) {
      if (!!result.error) {
        notify({
          type: NotificationType.error,
          message: result.error,
          title: 'Login failed'
        });
      } else if (!!result.response) {
        // dispatch log in actions
        // data.apiKey
        //response.cookies.sessionid
        //response.cookies.csrftoken
        dispatch(actions.user.dataChange({ username }));
        // dispatch(actions.user.setToken());
        dispatch(actions.user.apiKeyChange(result.response.apiKey));

        redirectToReferrer(username);
      }
    }

  }, [getUsePostUserLogIn, notify, redirectToReferrer, dispatch]);

  // TODO: check for already signed in
  return (
    <div className="container d-flex flex-column">
      <div className="row align-items-center justify-content-center no-gutters min-vh-100">
        <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">

          <LogInForm
            standalone={true}
            onLogIn={handleLogIn}
          />

        </div>
      </div>
    </div>
  );
}