import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

// Components
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

export default function RegionSelector({ regions, value, onChange = noop }) {
  const handleRegionChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!regions && !!regions.length ? (
        <select className="form-control form-control-sm" value={value} onChange={handleRegionChange}>
          <option value="">Select ...</option>
          {regions.map(({ id, name, country }, indx) => (
            <option key={`region-${indx}`} value={name}>
              {`${name} (${country})`}
            </option>
          ))}
        </select>
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}
    </React.Fragment>
  )
}