import React, { useCallback, useEffect, useRef } from 'react';
import { confirmable, createConfirmation } from 'react-confirm';

import { stopPreventEvent } from '~/lib/Utils';

function Confirmation(
  { proceedLabel,
    cancelLabel,
    title,
    confirmation,
    show,
    proceed,
    enableEscape = true }) {

  const modalRef = useRef(null);

  const handleCancelClick = useCallback((e) => {
    stopPreventEvent(e);

    proceed(false);
  }, [proceed]);

  const handleOkClick = useCallback((e) => {
    stopPreventEvent(e);

    if (!!modalRef.current) {
      // this is required because confirm above a modal causes an issue with bootstrap
      window.jQuery(modalRef.current).modal('hide');
    }

    proceed(true);
  }, [proceed, modalRef]);

  useEffect(() => {
    if (!!modalRef.current && show) {
      window.jQuery(modalRef.current).modal({
        backdrop: enableEscape ? true : 'static',
        keyboard: enableEscape
      });
    }
  }, [modalRef, show, enableEscape]);

  return (
    <div
      className="modal fade d-block"
      id="confirmModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered" role="dialog">
        <div className="modal-content">
          <div className="modal-header py-3">
            <h5 className="modal-title">
              {title || 'Confirm'}
            </h5>
          </div>

          <div className="modal-body">
            {confirmation}
          </div>

          <div className="modal-footer py-2">
            <button
              className="btn btn-primary-soft btn-sm lift"
              data-dismiss="modal"
              onClick={handleCancelClick}
            >
              {cancelLabel}
            </button>
            <button
              className="btn btn-primary btn-sm lift"
              onClick={handleOkClick}
            >
              {proceedLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}