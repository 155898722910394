import React, { useContext } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { Store, actions } from '~/store/StoreProvider';
import { LocalizationStore } from '~/i18n/LocalizationProvider';

export default function LanguageChooser() {
  const { state, dispatch } = useContext(Store);
  const { localization: { languages } } = useContext(LocalizationStore);

  const getHandleLanguageChange = useGetCallback(lang =>
    () => {
      dispatch(actions.app.setLanguage(lang));
    }, lang => lang, [dispatch]);

  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle text-uppercase"
        id="menu-language"
        data-toggle="dropdown"
        href="#!"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {state.language}
      </a>
      <div
        className="dropdown-menu dropdown-menu-md px-2"
        aria-labelledby="menu-language"
      >
        <div className="list-group list-group-flush mx-2">
          {!!languages && languages.map(({ code, name }, indx) => (
            <a
              key={`lang-${indx}`}
              className={`list-group-item ${state.language === code ? ' bg-gray-200' : ''} px-2`}
              href="#!"
              onClick={getHandleLanguageChange(code)}
            >
              <div className="icon icon-sm text-primary">
                <img src={`/assets/img/flags100px/${code}.png`} width="25" alt={name} />
              </div>
              <div className="ml-4">
                <h6 className="font-weight-bold text-uppercase text-primary mb-0">
                  {name}
                </h6>
              </div>
            </a>
          ))}
        </div>
      </div>
    </li>
  );
}