import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

// Components
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

export default function LanguageSelector({ languages, value, onChange = noop }) {
  const handleLanguageChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!languages && !!languages.length ? (
        <select className="form-control form-control-sm" value={value} onChange={handleLanguageChange}>
          <option value="">Select ...</option>
          {languages.map(({ code, name, nameOrig }, indx) => (
            <option key={`language-${indx}`} value={code} className="text-uppercase">
              {`${name} (${nameOrig})`}
            </option>
          ))}
        </select>
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}
    </React.Fragment>
  )
}