import React, { useMemo, useContext, useCallback } from 'react';
import { useGetCallback } from 'react-cached-callback';

import { noop, is, polarityFormatted } from '~/lib/Utils';

import { LocalizationStore } from '~/i18n/LocalizationProvider';

import {
  formatStatus,
  formatLanguageItem,
  formatDateInterval,
  formatPolarity
} from './Utils.Filter.js';

// Components
import Pills from '~/components/button/Pills';


const SourceSizeConstraints = {
  lt: 'Less than',
  gt: 'Greater than'
};

const SourceTypes = {
  '': 'All',
  general: 'General',
  sm: 'Social Media',
  paper: 'Scientific',
  report: 'Report',
  other: 'Other'
};

const ObjectTypes = {
  '': 'All',
  'app:readlst:ReadingList': 'Reading List',
  'app:sources:LibrarySource': 'Library Source',
  'app:topic:Topic': 'Topic',
  'feed:twitter:tweet': 'Social Media'
};


export default function FiltersList({
  filters,
  withRemove = true,
  withSelect = false,
  badgeStyle,
  closeStyle,
  closeButtonStyle,
  enableSourceTypeRemove = true,
  enableObjectTypeRemove = true,
  onLanguageRemove = noop,
  onOwnerRemove = noop,
  onContentTypeRemove = noop,
  onSourceSizeRemove = noop,
  onSourceTypeRemove = noop,
  onDateCreatedRemove = noop,
  onDateUpdatedRemove = noop,
  onLanguageSelect,
  onOwnerSelect,
  onContentTypeSelect,
  onSourceSizeSelect,
  onSourceTypeSelect,
  onDateCreatedSelect,
  onDateUpdatedSelect,
  onTopicSelect,
  onTopicRemove = noop,
  onPolaritySelect,
  onPolarityRemove = noop,
  onStatusSelect,
  onObjectTypeSelect,
  onObjectTypeRemove = noop,
  onStatusRemove = noop,
  onFilterRemove = noop,
  onRegionSelect = noop,
  onRegionRemove = noop
}) {

  const { getLanguageByCode } = useContext(LocalizationStore);

  const language = useMemo(() => {
    return !!filters && !!filters.language && is.func(getLanguageByCode)
      ? getLanguageByCode(filters.language)
      : ''
  }, [filters, getLanguageByCode]);

  const getHandleTopicRemove = useGetCallback(
    (topic) =>
      () => {
        onTopicRemove(topic)
      },
    [onTopicRemove]
  );

  const handleStatusRemove = useCallback(() => {
    onStatusRemove();
    onFilterRemove('status');
  }, [onStatusRemove, onFilterRemove]);

  const handleLangugeRemove = useCallback(() => {
    onLanguageRemove();
    onFilterRemove('language');
  }, [onLanguageRemove, onFilterRemove]);

  const handleOwnerRemove = useCallback(() => {
    onOwnerRemove();
    onFilterRemove('owner');
  }, [onOwnerRemove, onFilterRemove]);

  const handleContentTypeRemove = useCallback(() => {
    onContentTypeRemove();
    onFilterRemove('contentType');
  }, [onContentTypeRemove, onFilterRemove]);

  const handleSourceSizeRemove = useCallback(() => {
    onSourceSizeRemove();
    onFilterRemove('sourceSize');
  }, [onSourceSizeRemove, onFilterRemove]);

  const handleSourceTypeRemove = useCallback(() => {
    onSourceTypeRemove();
    onFilterRemove('sourceType');
  }, [onSourceTypeRemove, onFilterRemove]);

  const handleObjectTypeRemove = useCallback(() => {
    onObjectTypeRemove();
    onFilterRemove('objectType');
  }, [onObjectTypeRemove, onFilterRemove]);

  const handleDateCreatedRemove = useCallback(() => {
    onDateCreatedRemove();
    onFilterRemove('created');
  }, [onDateCreatedRemove, onFilterRemove]);

  const handleDateUpdatedRemove = useCallback(() => {
    onDateUpdatedRemove();
    onFilterRemove('updated');
  }, [onDateUpdatedRemove, onFilterRemove]);

  const handlePolarityRemove = useCallback(() => {
    onPolarityRemove();
    onFilterRemove('polarity');
  }, [onPolarityRemove, onFilterRemove]);

  const handleRegionRemove = useCallback(() => {
    onRegionRemove();
    onFilterRemove('region');
  }, [onRegionRemove, onFilterRemove]);

  const Badge = useMemo(() => {
    return withRemove
      ? Pills.BadgePillButtonWithClose
      : withSelect
        ? Pills.BadgePillButton
        : Pills.BadgePill
  }, [withRemove, withSelect]);

  return (
    <div className="d-inline-block ml-5">
      {is.notUndef(filters.active) && (
        <Badge
          title="Status"
          value={filters.active}
          badgeStyle={badgeStyle}
          formatFilterItem={formatStatus}
          onFilterRemove={handleStatusRemove}
          onClick={onStatusSelect}
        />
      )}

      {!!filters.language && (
        <Badge
          title="Language"
          value={language}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          formatFilterItem={formatLanguageItem}
          onFilterRemove={handleLangugeRemove}
          onClick={onLanguageSelect}
        />
      )}

      {!!filters.sourceSize && !!filters.sourceSize.constraint && !!filters.sourceSize.value && (
        <Badge
          title="Source size"
          value={`${SourceSizeConstraints[filters.sourceSize.constraint]} ${filters.sourceSize.value} KB`}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={handleSourceSizeRemove}
          onClick={onSourceSizeSelect}
        />
      )}

      {!!filters.sourceType && (
        <Badge
          title="Source type"
          value={SourceTypes[filters.sourceType]}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={enableSourceTypeRemove ? handleSourceTypeRemove : null}
          onClick={onSourceTypeSelect}
        />
      )}

      {!!filters.objectType && (
        <Badge
          title="Object type"
          value={ObjectTypes[filters.objectType]}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={enableObjectTypeRemove ? handleObjectTypeRemove : null}
          onClick={onObjectTypeSelect}
        />
      )}

      {!!filters.contentType && (
        <Badge
          title="Content type"
          value={filters.contentType}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={handleContentTypeRemove}
          onClick={onContentTypeSelect}
        />
      )}

      {!!filters.owner && (
        <Badge
          title="Owner"
          value={filters.owner}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={handleOwnerRemove}
          onClick={onOwnerSelect}
        />
      )}

      {!!filters.created && (!!filters.created.from || !!filters.created.to) && (
        <Badge
          title="Created"
          value={filters.created}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          formatFilterItem={formatDateInterval}
          onFilterRemove={handleDateCreatedRemove}
          onClick={onDateCreatedSelect}
        />
      )}

      {!!filters.updated && (!!filters.updated.from || !!filters.updated.to) && (
        <Badge
          title="Updated"
          value={filters.updated}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          formatFilterItem={formatDateInterval}
          onFilterRemove={handleDateUpdatedRemove}
          onClick={onDateUpdatedSelect}
        />
      )}

      {!!filters.topics && !!filters.topics.topics && filters.topics.topics.map((topic, indx) => (
        <Badge
          key={`topic-${indx}`}
          title={filters.topics.topics.length < 2 ? 'Topic' : `Topics (${filters.topics.constraint})`}
          value={topic}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={getHandleTopicRemove(topic)}
          onClick={onTopicSelect}
        />
      ))}

      {is.notUndef(filters.polarity) && filters.polarity !== 0 && (
        <Badge
          title="Polarity"
          value={polarityFormatted(filters.polarity)}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          formatFilterItem={formatPolarity}
          onFilterRemove={handlePolarityRemove}
          onClick={onPolaritySelect}
        />
      )}

      {!!filters.region && (
        <Badge
          title="Region"
          value={filters.region}
          badgeStyle={badgeStyle}
          closeButtonStyle={closeButtonStyle}
          onFilterRemove={handleRegionRemove}
          onClick={onRegionSelect}
        />
      )}

      {is.notUndef(filters.polarityInterval) && filters.polarityInterval !== 0 && (
        <Badge
          title="Polarity interval"
          value={filters.polarityInterval}
          badgeStyle={badgeStyle}
        />
      )}
    </div>
  )
}