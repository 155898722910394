import React from 'react';

// Components
import Logo from './Brand/Logo';

import logo from '~/img/logo_SEMEX.png';


export default function Brand() {
  return (
    <Logo logo={logo} />
  );
}