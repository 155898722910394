import React, { useCallback } from 'react';
import { createGlobalStyle } from 'styled-components';

import { noop } from '~/lib/Utils';

// Components
import Nouislider from 'react-nouislider';

import 'nouislider/distribute/nouislider.min.css';

const NouiGlobalOverrides = createGlobalStyle`
.slider-primary .noUi-connect {
  background: var(--primary) !important;
}

.slider-primary.noUi-connect {
  background: var(--primary) !important;
}

.slider-primary .noUi-handle {
  border-color: var(--primary) !important;
}

.slider-primary.circle-filled .noUi-handle,
.slider-primary.square .noUi-handle {
  background: var(--primary) !important;
}

.noUi-tooltip {
  background-color: rgba(51,94,234,.1) !important;
  color: var(--primary) !important;
  border-color: transparent !important;
  border-radius: 1rem !important;
  font-size: 80% !important;
  line-height: 1rem !important;
  padding: 0.25rem 0.625rem !important;
  margin-bottom: 0.5rem !important;
}

.noUi-handle {
  border-radius: 50% !important;
  border-width: 0.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;

  &:after, &:before {
    display: none !important;
  }
}

.slider-sm {
  .noUi-target.noUi-horizontal {
    height: 0.5rem !important;
    background: rgba(51,94,234,.1) !important;
    border-color: transparent !important;
    border-radius: 1rem !important;

    .noUi-origin {
      margin-top: -0.25rem;
    }
  }
}
`;

export default function PolaritySelector({ value, onChange = noop }) {
  const handlePolarityChange = useCallback((polarity) => {
    onChange(polarity)
  }, [onChange]);

  return (
    <div className="mt-8 mx-3 slider-primary slider-sm">
      <NouiGlobalOverrides />
      <Nouislider
        range={{ min: -1, max: 1 }}
        start={value.map(Number)}
        step={0.1}
        tooltips={true}
        connect={true}
        behaviour="tap-drag"
        onChange={handlePolarityChange}
      />
    </div>
  )
}