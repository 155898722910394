import { useMemo } from 'react';

import { useFetchDeferred } from '~/lib/Effects.Fetch';

export function usePostUserLogIn() {
  const options = useMemo(() => {
    return ({
      method: 'POST',
      path: 'user/login/',
      data: null // will be set on post()
    });
  }, []);

  return useFetchDeferred(options);
}