export function getPolarityColor(polarity) {
  return Number(polarity) < 0
    ? 'danger'
    : Number(polarity) > 0
      ? 'success'
      : 'secondary';
}

export function filterSameLocations(location, indx, locations) {
  const nextIndx = locations.findIndex(loc =>
    loc.country === location.country &&
    loc.name === location.name &&
    loc.coords.lat === location.coords.lat &&
    loc.coords.lon === location.coords.lon);

  return nextIndx === indx;
}

export function parseQuery(query) {
  const phrase = (query || '').split('topic:');

  return ({
    query: phrase[0].trim(),
    topics: !!phrase[1]
      ? phrase[1].trim().split('|')
      : []
  });
}