import React, { forwardRef } from 'react';

// Components
import DropdownHoverMenu from './DropdownHoverMenu';

export default forwardRef(function LibraryDescriptionPanel(_props, ref) {
  return (
    <DropdownHoverMenu ref={ref}>
      <div className="d-flex no-gutters">
        <div className="dropdown-img-left col-6" style={{ backgroundImage: 'url(/assets/img/photos/photo-14.jpg)' }}>
          <h4 className="font-weight-bold text-white mb-0">
            Library
          </h4>
        </div>
        <div className="ml-auto col-6 dropdown-body small">
          Find a specific article filtering by language, owner etc…
        </div>
      </div>
    </DropdownHoverMenu>
  );
});