import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { noop } from '~/lib/Utils';

// Components
import Autosuggest from 'react-autosuggest';
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

const renderSuggestion = organization => (
  <div className="d-flex w-100 flex-column">
    <span>
      {organization.fullName || organization.name}
    </span>
    <span className="small text-gray-700">
      {organization.organizationType}
    </span>
  </div>
);

const renderInputComponent = inputProps => (
  <div>
    <input {...inputProps} className="form-control form-control-sm" />
  </div>
);

const getSuggestionValue = organization => organization.name;

const shouldRenderSuggestions = () => true;

export default function OrganizationSelector({ organizations, value, placeholder = 'Type to search an owner...', onChange = noop }) {
  const [prompt, setPrompt] = useState(value);
  const [suggestions, setSuggestions] = useState(organizations);

  useEffect(() => {
    setPrompt(value);
  }, [value]);

  useEffect(() => {
    setSuggestions(organizations)
  }, [organizations]);

  const onSuggestionsFetchRequested = useCallback(({ value }) => {
    const val = value.toLowerCase();

    !!organizations && setSuggestions(organizations
      .filter(org => !value
        || org.fullName.toLowerCase().indexOf(val) >= 0
        || org.name.toLowerCase().indexOf(val) >= 0
        || org.organizationType.toLowerCase().indexOf(val) >= 0));
  }, [organizations]);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const handleInputChange = useCallback((_e, { newValue }) => {
    setPrompt(newValue);
  }, []);

  const handleSuggestionSelected = useCallback((_e, { suggestionValue }) => {
    onChange(suggestionValue)
  }, [onChange]);

  const inputProps = useMemo(() => {
    return ({
      placeholder,
      value: prompt,
      onChange: handleInputChange
    });
  }, [prompt, placeholder, handleInputChange]);

  return (
    <React.Fragment>
      {!!organizations && !!organizations.length && !!suggestions ? (
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
          onSuggestionSelected={handleSuggestionSelected}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}
    </React.Fragment>
  );
}