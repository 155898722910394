import React, { createContext, useReducer } from 'react';
import { createSymbiote } from 'redux-symbiote';

import {
  initialState as commonInitialState,
  symbiotes as commonSymbiotes
} from '../modules/main/store';

import {
  initialState as adminLibraryInitialState,
  symbiotes as adminLibrarySymbiotes
} from '../modules/library/store';

import {
  initialState as explorerInitialState,
  symbiotes as explorerSymbiotes
} from '../modules/explorer/store';

import {
  initialState as userInitialState,
  symbiotes as userSymbiotes
} from '../modules/user/store';

// context object
const Store = createContext();

const initialState = ({
  ...commonInitialState,
  ...adminLibraryInitialState,
  ...explorerInitialState,
  ...userInitialState
});

const symbiotes = ({
  ...commonSymbiotes,
  ...adminLibrarySymbiotes,
  ...explorerSymbiotes,
  ...userSymbiotes
});

const { actions, reducer } = createSymbiote(initialState, symbiotes);

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = ({ state, dispatch });

  return (
    <Store.Provider value={value}>
      {children}
    </Store.Provider>
  );
}

export {
  Store,
  StoreProvider,
  actions
};
