import { camelCase, snakeCase, pascalCase, sentenceCase } from 'change-case';
import { titleCase } from 'title-case';
import { sizeFormatter } from 'human-readable';
import { format as formatDate } from 'date-fns';
import prettyMilliseconds from 'pretty-ms';

/*eslint-disable no-extend-native*/
if (!Array.prototype.last) {
  Array.prototype.last = function (predicate = null) {
    return this.length <= 0 ?
      undefined :
      !!predicate ?
        this.reduce((tot, i, indx) => predicate(i, indx) || tot, undefined) :
        this[this.length - 1];
  };
};

if (!Array.prototype.first) {
  Array.prototype.first = function (predicate = null) {
    return this.length <= 0 ?
      undefined :
      !!predicate ?
        this.reduce((tot, i, indx) => tot || (predicate(i, indx) ? i : tot), undefined) :
        this[0];
  };
};

if (!Array.prototype.sum) {
  Array.prototype.sum = function (predicate = null) {
    return this.length <= 0 ?
      undefined :
      this.reduce((tot, i, indx) => tot + (!!predicate ? predicate(i, indx) : i), 0);
  };
};

if (!Array.prototype.take) {
  Array.prototype.take = function (num) {
    return this.length <= 0 || this.length <= num ?
      this :
      this.filter((_i, indx) => indx < num);
  }
}

if (!Array.prototype.takeRight) {
  Array.prototype.takeRight = function (num) {
    return this.length <= 0 || this.length <= num ?
      this :
      this.filter((_i, indx) => indx >= this.length - num);
  }
}
/*eslint-enable no-extend-native*/

export { formatDate };

export function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise =
    new Promise((resolve, reject) => {
      promise
        .then(
          val => (isCanceled
            ? reject({ isCanceled })
            : resolve(val))
        )
        .catch(
          error => (isCanceled
            ? reject({ isCanceled })
            : reject(error))
        );
    });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export function noop() { }

export const is = {
  number: (val) => val !== null && val !== undefined && !isNaN(Number(val)),
  string: (val) => val !== null && typeof val === "string",
  array: (val) => val !== null && Array.isArray(val),
  notUndef: (val) => val !== null && val !== undefined,
  undef: (val) => val === null || val === undefined,
  func: (val) => val !== null && val !== undefined && typeof val === 'function',
  object: (val) => val !== null && val !== undefined && typeof val === 'object',
  nullOrEmptyString: (val) => !val || !val.trim()
};

function _objectToCase(obj, convertFn) {
  if (is.undef(obj) || is.undef(convertFn) || !is.func(convertFn)) {
    return obj;
  } else if (is.array(obj)) {
    return obj.map(o => _objectToCase(o, convertFn));
  } else if (!is.object(obj)) {
    return (obj);
  }

  let result = {};

  Object.keys(obj).forEach(key => {
    result[convertFn(key)] = is.array(obj[key])
      ? obj[key].map(o => _objectToCase(o, convertFn))
      : is.object(obj[key])
        ? _objectToCase(obj[key], convertFn)
        : obj[key];
  });

  return result;
}

export function objectToCamelCase(obj) {
  return _objectToCase(obj, camelCase);
}

export function objectToSnakeCase(obj) {
  return _objectToCase(obj, snakeCase)
}

export function objectToPascalCase(obj) {
  return _objectToCase(obj, pascalCase);
}

function _stringToCase(value, convertFn) {
  if (is.undef(value) || is.undef(convertFn) || !is.func(convertFn)) {
    return value;
  } else if (is.array(value)) {
    return value.map(o => _stringToCase(o, convertFn));
  } else if (is.object(value)) {
    return (value);
  }

  return convertFn(value);
}

export function stringToSentenseCase(str) {
  return _stringToCase(str, sentenceCase);
}

export function stringToTitleCase(str) {
  return _stringToCase(str, titleCase);
}

export function stringToSnakeCase(str) {
  return _stringToCase(str, snakeCase);
}

export const formatFileSize = sizeFormatter({
  std: 'SI', // 'SI' (default) | 'IEC' | 'JEDEC'
  decimalPlaces: 2,
  keepTrailingZeroes: false,
  render: (literal, symbol) => `${literal} ${symbol}B`,
});

export function formatTimeInterval(msecs, precision = 'second') {
  const mods = [1000, 1000 * 60, 1000 * 60 * 60, 1000 * 60 * 60 * 24];
  const precs = ['second', 'minute', 'hour', 'day'];

  const mod = mods[precs.findIndex(p => p === precision)];
  const value = Math.ceil(msecs / mod) * mod;

  return prettyMilliseconds(value, { verbode: true });
}

export function toCapitalCase(str) {
  return !str || !str.length
    ? str
    : `${str[0].toUpperCase()}${str.substr(1)}`;
}

export function createElement(element, classNames = "", parent = null) {
  const el = document.createElement(element);
  el.className = classNames;

  if (parent) {
    parent.appendChild(el);

    return [el, () => parent.removeChild(el)];
  }

  return el;
}

function _sortByCast(getA, getB, cast, reversed) {
  return (a, b) => {
    let aVal = is.func(getA) ? cast(getA(a)) : cast(a);
    let bVal = is.func(getB) ? cast(getB(b)) : cast(b);

    if (reversed) {
      [aVal, bVal] = [bVal, aVal];
    }

    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
  }
}

export function sortByNumber(getA, getB, reversed = false) {
  return _sortByCast(getA, getB, Number, reversed);
}

export function sortByString(getA, getB, reversed = false) {
  return _sortByCast(getA, getB, String, reversed);
}

export function scrollOnTopAnimated(element, duration, callback) {
  const d = is.func(duration) ? 500 : duration;
  const c = is.func(duration) ? duration : is.func(callback) ? callback : noop;

  if (!!element) {
    window.jQuery(element === window
      ? document.documentElement
      : element)
      .stop()
      .animate({ scrollTop: 0 }, d, 'swing', c);
  }
}

export function stopPreventEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function eventTargetBlur(e) {
  !!e && !!e.currentTarget && is.func(e.currentTarget.blur) && e.currentTarget.blur();
}

export function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

export function openUrlNoOpener(url, target = '_blank') {
  const a = document.createElement('a');
  a.href = url;
  a.target = target;
  a.rel = 'noopener noreferrer';
  a.click();
}



export function delay(timeoutMsec, promiseValue = true) {
  // Convert onmessage function below into base64 format in any online converter and put into Worker .ctor
  const worker = new Worker('data:text/javascript;base64,b25tZXNzYWdlID0gZnVuY3Rpb24gKGV2dCkgeyBzZXRUaW1lb3V0KGZ1bmN0aW9uICgpIHsgcG9zdE1lc3NhZ2UoJ2RlbGF5JywgbnVsbCkgfSwgZXZ0LmRhdGEpOyB9');

  const promise = new Promise((resolve, reject) => {
    worker.onmessage = () => resolve(promiseValue);
    worker.onerror = () => reject();

    worker.postMessage(timeoutMsec);
  });

  promise.cancel = () => worker.terminate();

  return promise;
}

export function polarityFormatted(polarity = 0, decimals = 1, fullDecimals = 3) {
  if (isNaN(Number(polarity))) {
    return 0;
  }

  const sign = Math.sign(polarity);
  const mod = Math.abs(polarity);

  const dec = Math.pow(10, decimals);

  const low = parseInt(Number(mod) * dec) / dec;
  const high = low + (1 / dec);

  return (mod - low) > (high - mod)
    ? Number(Number(polarity).toFixed(fullDecimals))
    : low * sign;
}