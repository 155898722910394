import React, { useCallback } from 'react';

import { noop } from '~/lib/Utils';

// Components
import LoadingIndicator from '~/components/indicator/LoadingIndicator';

export default function ObjectTypeSelector({ objectTypes, value, onChange = noop }) {
  const handleObjectTypeChange = useCallback(({ target: { value } }) => {
    onChange(value);
  }, [onChange]);

  return (
    <React.Fragment>
      {!!objectTypes && !!objectTypes.length ? (
        <select className="form-control form-control-sm" value={value} onChange={handleObjectTypeChange}>
          <option value="">All</option>
          {objectTypes.map(({ code, name }, indx) => (
            <option key={`object-type-${indx}`} value={code}>{name}</option>
          ))}
        </select>
      ) : (
          <LoadingIndicator size={1} length={3} className="text-primary-desat py-1" />
        )}

    </React.Fragment>
  );
}