import React, { forwardRef } from 'react';

// Components
import { NavLink } from 'react-router-dom';

function DropdownMenuItem({ title, description, img, badge }) {
  return (
    <React.Fragment>
      {!!img && (
        <div className="icon icon-sm text-primary">
          <img src={img} alt="..." width="24" />
        </div>
      )}

      <div className="ml-4">
        <h6 className="font-weight-bold text-uppercase text-primary mb-0">
          {title}
        </h6>
        {!!description && (
          <p className="font-size-sm text-gray-700 mb-0">
            {description}
          </p>
        )}
      </div>

      {!!badge && (
        <span className="badge badge-pill badge-primary-soft ml-auto">
          {badge}
        </span>
      )}
    </React.Fragment>
  );
}

export default forwardRef(function AnalyticsescriptionPanel(_props, ref) {
  return (
    <div className="dropdown-hover-menu dropdown-menu dropdown-menu-md px-2" ref={ref}>
      <div className="list-group list-group-flush mx-2">
        <NavLink className="list-group-item px-2" to="/explorer">
          <DropdownMenuItem
            title="Topic Explorer"
            description="Explore GEMET topics in an interactive way"
            img="/assets/img/icons/duotone-icons/Code/Git_4.svg"
          />
        </NavLink>

        <NavLink className="list-group-item px-2" to="/polarity-score">
          <DropdownMenuItem
            title="Polarity Score"
            description="Visualise sentiment analysis from specific sources"
            img="/assets/img/icons/duotone-icons/Shopping/Chart-bar-3.svg"
          />
        </NavLink>

        <a className="list-group-item px-2" href="https://elastic.kajoservices.com/">
          <DropdownMenuItem
            title="Analytics"
            description="Analyze data through Kibana visualizations"
            img="/assets/img/icons/duotone-icons/Shopping/Chart-pie.svg"
            badge="v1.2.1"
          />
        </a>
      </div>
    </div>
  );
});