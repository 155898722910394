import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useFilters } from '../lib/Search.Effects';

import { scrollOnTopAnimated } from '~/lib/Utils';
import { useScrollOnTopOnChange } from '~/lib/Effects';

import { Store, actions } from '~/store/StoreProvider';
import { LocalizationStore } from '~/i18n/LocalizationProvider';

import {
  useFetchSearch
} from '../api';

import {
  useFetchContentTypes,
  useFetchOrgarizations,
  useFetchSourceTypes,
  useFetchTopicSuggestions,
  useFetchRegions
} from '~/modules/library/api';

// Component
import LoadingIndicator from '~/components/indicator/LoadingIndicator';
import SearchResults from '../components/SearchResults';
import SearchControl from '../components/SearchControl';
import SelectedSourcesPanel from '../components/SearchSelectedSourcesPanel';

import SideBar from '~/modules/library/components/SideBar';
import FiltersContainer from '../components/FiltersContainer';
import FiltersList from '~/modules/library/components/FiltersPanel/FiltersList';


// Warning! Server has no endpoint for this dictionary
const ObjectTypes = [
  { code: 'app:sources:LibrarySource', name: 'Library' },
  { code: 'app:readlst:ReadingList', name: 'Curated Reading List' }
];

const ScopeTypes = [
  'text',
  'summary',
  'description',
  'url',
  'domain',
  'author'
];

const FiltersInitialState = {
  language: '',
  sourceSize: {
    constraint: '',
    value: 0
  },
  contentType: '',
  sourceType: '',
  owner: '',
  region: '',
  polarity: [-1, 1],
  created: {},  // from, to
  updated: {}, // from, to,
  objectType: '',
  topics: {
    topics: [],
    constraint: 'all'
  },
  scope: ScopeTypes
};

export default function SearchPage() {
  const { state, dispatch } = useContext(Store);
  const history = useHistory();

  const { localization: { languages } } = useContext(LocalizationStore);

  const [query, setQuery] = useState('');
  const {
    filters,
    updateFilter
  } = useFilters(FiltersInitialState);

  const [{ response, loading, error }, { reload, next }] = useFetchSearch(query, filters);

  const [{ response: contentTypes }] = useFetchContentTypes(state.user.data, state.user.apiKey);
  const [{ response: organizations }] = useFetchOrgarizations(state.user.data, state.user.apiKey);
  const [{ response: sourceTypes }] = useFetchSourceTypes(state.user.data, state.user.apiKey);
  const [{ response: regions }] = useFetchRegions(state.user.data, state.user.apiKey);

  const [searchTopicQuery, setSearchTopicQuery] = useState('');
  const [{ response: searchTopicSuggestions, loading: searchTopicLoading }] = useFetchTopicSuggestions(state.language, searchTopicQuery);

  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (!!response && !!response.objects) {
      setShowResults(!!query && !!query.trim());  // TODO: fix redundant server call with a previous query in case useFetch url is null
    }
  }, [response, query]);

  const { scope, term } = useParams();

  useEffect(() => {
    if (!!scope) {
      if (scope.toLowerCase() === 'explorer') {
        setQuery(`topic:${state.explorer.topics.documentTopics.map(({ topic }) => topic).join('|')}`);
      } else if (scope.toLowerCase() === 'crl') {
        setQuery(term);
      }
    } else if (!!term) {
      setQuery(term);
    }
  }, [scope, term, state.explorer.topics]);


  const handleFetchNext = useCallback(() => {
    next(true);
  }, [next]);

  const handleTopicSelect = useCallback((topic) => {
    scrollOnTopAnimated(window, () => {
      setShowResults(false);
      setQuery(`topic:${topic}`);
    });
  }, []);


  const list = useMemo(() => {
    return !!state && state.library.source.list;
  }, [state]);

  const crlSelection = useMemo(() => {
    return (!!list && list.selectedSources) || [];
  }, [list]);

  const handleCrlSourceSelect = useCallback((source) => {
    dispatch(actions.library.source.list.selectedSourcesChange([...new Set(state.library.source.list.selectedSources.concat(source))]));
  }, [state, dispatch]);

  const handleCrlSourceRemove = useCallback((source) => {
    if (state.library.source.list.selectedSources.length === 1) {
      window.setTimeout(() => setShowResults(true), 0);
    }

    dispatch(actions.library.source.list.selectedSourcesChange(state.library.source.list.selectedSources.filter(s => s.sourceId !== source.sourceId)));
  }, [state, dispatch]);

  // WARNING! This is REQUIRED to let AOS calculate anchor position properly (after selection panel disappear)
  // NOTE. Results and selection must not be visible simultaneously
  const handleCrlSelectedSourcesShowChange = useCallback((show) => {
    window.setTimeout(() => setShowResults(!show), 0);
  }, []);

  const handleCrlSourcesAdd = useCallback(() => {
    history.push('/modal/reading-list/create');
  }, [history]);

  const handleFilterChange = useCallback((name, value) => {
    updateFilter(name, value);
  }, [updateFilter]);

  const handleFilterRemove = useCallback((name) => {
    updateFilter(name, null);
  }, [updateFilter]);

  const handleTopicRemove = useCallback((topic) => {
    updateFilter('topics', {
      topics: filters.topics.topics.filter(t => t !== topic),
      constraint: filters.topics.constraint
    });
  }, [filters, updateFilter]);

  const handleScopeToggle = useCallback((scope) => {
    updateFilter(
      'scope',
      filters.scope.some(s => s === scope)
        ? filters.scope.filter((s, _i, arr) => arr.length === 1 || s !== scope)
        : filters.scope.concat(scope)
    );
  }, [filters, updateFilter]);

  const searchContainerRef = useRef(null);

  useEffect(() => {
    if (!!filters && !!searchContainerRef.current) {
      searchContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filters, searchContainerRef]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 col-lg-4 order-2 order-lg-1">
          <SideBar id="source-sidebar">
            <FiltersContainer
              className="w-100"
              filters={filters}
              languages={languages}
              contentTypes={contentTypes}
              organizations={organizations}
              sourceTypes={sourceTypes}
              objectTypes={ObjectTypes}
              regions={regions}
              topics={searchTopicSuggestions}
              topicsLoading={searchTopicLoading}
              onTopicsFetch={setSearchTopicQuery}
              onFilterChange={handleFilterChange}
            />
          </SideBar>
        </div>

        <div className="col-xs-12 col-lg-8 order-1 order-lg-2 pl-lg-6 pt-5">
          <div className="shape shape-blur-3  svg-shim text-dark">
            <svg viewBox="0 0 1738 487" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z" fill="url(#paint0_linear)"></path>
              <defs>
                <linearGradient id="paint0_linear" x1="0" y1="0" x2="1049.98" y2="912.68" gradientUnits="userSpaceOnUse">
                  <stop stopColor="currentColor" stopOpacity=".035"></stop>
                  <stop offset="1" stopColor="currentColor" stopOpacity="0"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="w-100 mb-3">
            <SearchControl
              query={query}
              debounce={500}
              searchScopes={ScopeTypes}
              filters={filters}
              onScopeToggle={handleScopeToggle}
              onQueryChange={setQuery}
              onForceQuery={reload}
            />

            <div className="text-center mb-5">
              <small className="form-text text-muted">
                <span className="text-uppercase mr-3">Common searches:</span>
                  CAP, depopulation in rural areas, climate mitigation, etc.<br />
                <span className="text-uppercase mr-3">Use filters</span>
                  to precise the search results ( or topic:&lt;topic&gt;|&lt;topic&gt; query modifier )
                </small>
            </div>

            <FiltersList
              filters={filters}
              withRemove={true}
              withSelect={false}
              onFilterRemove={handleFilterRemove}
              onTopicRemove={handleTopicRemove}
            />

            {!!list.selectedSources.length && (
              <div className="justify-content-sm-start justify-content-md-center d-flex flex-sm-column flex-md-row mt-5">
                <SelectedSourcesPanel
                  sources={list.selectedSources}
                  onSourceRemove={handleCrlSourceRemove}
                  onSelectedSourcesShowChange={handleCrlSelectedSourcesShowChange}
                  onApply={handleCrlSourcesAdd}
                />
              </div>
            )}

          </div>
        </div>

        <div className="col-xs-12 col-lg-4 order-3 invisible"></div>

        <div className="col-xs-12 col-lg-8 order-4 pl-lg-6">
          <div className="w-100 mt-5" ref={searchContainerRef}>
            {!!loading && (!response || !response.objects || !response.objects.length) ? (
              <div className="col-12 text-center">
                <LoadingIndicator className="text-primary-desat py-5" />
              </div>
            ) : showResults && !!response && !!response.meta && !error && (
              <SearchResults
                results={response.objects}
                meta={response.meta}
                crlSelection={crlSelection}
                onFetchNext={handleFetchNext}
                onTopicSelect={handleTopicSelect}
                onCrlSourceSelect={handleCrlSourceSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}