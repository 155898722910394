import React, { useEffect, useRef, useCallback, useState } from 'react';

import { noop, scrollOnTopAnimated } from '~/lib/Utils';

import { useElementEvent } from '~/lib/Effects';
import styled from 'styled-components';


const ScrollOnTopButtonStyled = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease, transform 0.25s ease;
  opacity: ${ /* sc-value */ ({ show }) => show ? '1' : '0'} !important;
  visibility: ${ /* sc-value */ ({ show }) => show ? 'visible' : 'hidden'} !important;
`;

function ScrollOnTop({ element, show }) {
  const buttonRef = useRef(null);

  const handleButtonClick = useCallback(() => {
    scrollOnTopAnimated(element, () => !!buttonRef.current && buttonRef.current.blur());
  }, [element, buttonRef]);

  return (
    <ScrollOnTopButtonStyled
      ref={buttonRef}
      show={show}
      className="btn btn-lg btn-rounded-circle btn-primary-soft lift mr-5 mb-5"
      onClick={handleButtonClick}
    >
      <img src="/assets/img/icons/duotone-icons/Navigation/Up-2.svg" alt="On top" width="48" />
    </ScrollOnTopButtonStyled>
  );
}


function useMouseWheelListener(element, callback) {
  return useElementEvent(element, 'mousewheel', callback);
}

function useScrollListener(element, callback) {
  return useElementEvent(element, 'scroll', callback);
}

function useResizeListener(element, callback) {
  return useElementEvent(element, 'resize', callback);
}

function mousewheelListener(e) {
  // Prevents Chrome hangups
  // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
  if (e.deltaY === 1) {
    e.preventDefault();
    e.stopPropagation();
  }
}

function calculateOffset(el, scrollTop) {
  if (!el) {
    return 0;
  }

  return (
    calculateTopPosition(el) +
    (el.offsetHeight - scrollTop - window.innerHeight)
  );
}

function calculateTopPosition(el) {
  if (!el) {
    return 0;
  }

  return (
    el.offsetTop +
    calculateTopPosition(el.offsetParent)
  );
}

function getParentElement(el) {
  return el && el.parentNode;
}

function getWindowTopOffset() {
  const doc = document.documentElement || document.body.parentNode || document.body;

  return window.pageYOffset !== undefined
    ? window.pageYOffset
    : doc.scrollTop;
}

export default function InfiniteScroll({
  element = window,
  hasMore = false,
  threshold = 250,
  loader,
  loadMore = noop,
  children
}) {

  const scrollComponent = useRef(null);
  const ignoreEvents = useRef(false);

  const [showScrollOnTop, setShowScrollOnTop] = useState(false);

  const scrollListener = useCallback(() => {

    if (!scrollComponent.current) {
      return;
    }

    if (ignoreEvents.current) {
      return;
    }

    if (!element) {
      return;
    }

    const parentElement = getParentElement(scrollComponent.current);

    if (!parentElement) {
      return;
    }

    const el = scrollComponent.current;

    let offset = window.innerHeight;

    if (element === window) {
      offset = calculateOffset(el, getWindowTopOffset());
    } else {
      offset = parentElement.clientHeight - element.clientHeight - element.scrollTop;
    }

    // Here we make sure the element is visible as well as checking the offset
    if (
      offset < Number(threshold) &&
      (el && el.offsetParent !== null) &&
      hasMore
    ) {
      ignoreEvents.current = true;
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      loadMore();
    }

    const scrollTop = element === window
      ? getWindowTopOffset() > document.documentElement.clientHeight
      : element.scrollTop > element.clientHeight;

    setShowScrollOnTop(scrollTop);

  }, [scrollComponent, element, threshold, loadMore, hasMore]);

  useEffect(() => {
    ignoreEvents.current = false;
  }, [children]);

  useMouseWheelListener(element, mousewheelListener);
  useScrollListener(element, scrollListener);
  useResizeListener(element, scrollListener);


  return (
    <div ref={scrollComponent}>
      {children}
      {hasMore && !!loader && loader}
      <ScrollOnTop show={showScrollOnTop} element={element} />
    </div>
  );
}
